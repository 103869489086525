.invoice {
	height: 40px;
}

tr.invoice {
	border-bottom: 1px solid var(--collapsible-header-border-color);
}

.invoice__mobile-label {
	display: none;
	font-weight: bold;
}

td.invoice__icon {
	height: inherit;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

td.invoice__icon .info-icon svg {
	padding: 5px;
	fill: var(--primary-color);
}

td.invoice__icon .invoice-tooltip {
	margin-top: 0;
	border-radius: 15px;
}

.invoice__icon i {
	font-size: 18px;
	color: var(--success-green-color);
}

.invoice__icon i.error {
	color: var(--error-color);
}

.invoice__body {
	display: flex;
	flex-direction: row;
}
.invoice__type {
	padding-right: var(--spacing-half);
}
.invoice__footer {
	display: flex;
	flex-direction: row;
	align-items: flex-end;
	padding: var(--spacing-half) 0 0 0;
}
.invoice__date {
	display: flex;
	flex-direction: column;
	flex: 1 0 60%;
}
.invoice .download-link {
	margin-left: auto;
}

.input__date-row {
	display: flex;
	justify-content: flex-start;
	text-transform: capitalize;
}

.input__date-row p {
	margin: 0 0px 8px 8px;
	font-size: 12px;
}
.invoice .tooltip-content thead {
	width: unset;
	text-align: left;
}
.invoice .tooltip-content tbody {
	width: unset;
	border: none;
}

.invoice .tooltip-content tbody tr td {
	text-align: left;
	white-space: nowrap;
}

.invoice .tooltip-content .rest-amount,
.invoice .tooltip-content .payment-reference {
	padding: 5px 0;
}
.invoice .tooltip-content .headline {
	padding-left: 1px;
	padding-bottom: 15px;
}
.invoice .tooltip-content table td {
	padding: 0 15px 0 0;
}

.invoice .tooltip-content table tr td:first-child,
.invoice .tooltip-content table tr td:last-child {
	padding-top: 3px;
}

.invoice .tooltip-content table td:last-child {
	padding: 0;
}
.invoice .tooltip-content table thead tr th {
	padding: 0 0 15px 0;
}

@media only screen and (max-width: 767px) {
	.invoice__mobile-label {
		display: inline;
		margin: var(--spacing-half) var(--spacing-half) var(--spacing-half) 0;
	}

	td.invoice__icon {
		position: relative;
		justify-content: unset;
		height: unset;
	}

	td.invoice__icon span.info-icon {
		position: absolute;
		right: 50px;
	}

	td.invoice__icon span.info-icon svg {
		padding: 10px;
	}

	.invoice .tooltip-content thead th {
		display: table-cell;
	}
	.invoice .tooltip-content tbody tr td {
		display: table-cell;
		width: unset;
		align-items: unset;
	}
}

@media only screen and (min-device-width: 320px) and (max-device-width: 360px) {
	.invoice__footer {
		display: block;
	}

	.invoice__type {
		display: inline-block;
		width: 40%;
	}

	.currency {
		margin: auto;
	}
	.invoice__date {
		display: inline-block;
		float: left;
	}

	.invoice__balance {
		display: inline-block;
		float: right;
	}
}

@media only screen and (min-device-width: 375px) and (max-device-width: 414px) {
	.invoice__footer {
		display: block;
	}
	.invoice__date {
		display: inline-block;
		float: left;
	}

	.invoice__body {
	}

	.invoice__type {
		display: inline-block;
		width: 40%;
	}
	.currency {
		margin: auto;
	}

	.invoice__balance {
		display: inline-block;
		float: right;
		white-space: pre;
	}
}
