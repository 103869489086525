.email {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	margin-bottom: 0;
	margin-top: 0;
}

.email > span:first-child {
	display: inline-flex;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	flex: 1 1 0%;
	width: 1px;
}

.email > span:first-child > span:last-child {
	display: inline-flex;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.email > span:first-child * {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.email svg {
	fill: var(--default-text-color);
}

.email div {
	/* width: 1px;
	flex: 1 1 0%; */
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
