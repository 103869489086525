.under-construction {
	width: 450px;
	margin: 100px auto;
	padding-bottom: 20px;
}

.under-construction .logo {
	text-align: center;
}
.under-construction .logo {
	margin-bottom: 80px;
}

.under-construction .logo img {
	max-width: 300px;
	width: 100%;
}
.under-construction .content {
	margin: 0 auto;
}
.under-construction .title {
	display: flex;
	align-items: center;
}
.under-construction hr {
}

.under-construction .message {
	padding-top: 20px;
}
.under-construction h1 {
	font-weight: 300;
	text-transform: uppercase;
}

.under-construction footer {
	display: flex;
	flex-direction: column;
	position: fixed;
	bottom: 0;
	left: 0;
	width: 100%;
	padding: 30px;
	text-align: center;
	color: var(--secondary-color);
	border-top: 1px solid rgba(0, 0, 0, 0.1);
	background-color: rgba(255, 255, 255, 1);
}

.under-construction .lang-switch-navigation {
	position: fixed;
	top: 0;
	right: 10px;
}

@media only screen and (max-width: 767px) {
	.under-construction {
		width: 70%;
		margin: 70px auto;
		padding-bottom: 30px;
	}

	.under-construction .logo {
		margin-bottom: 40px;
	}

	.under-construction .logo img {
		width: 80%;
	}

	.under-construction footer {
		padding: 15px;
	}
}
