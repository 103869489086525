.__react_component_tooltip {
	pointer-events: auto !important;
}

.view-agreements .extra-tooltip {
	background-color: var(--tooltip-background-color);
	border-radius: var(--border-radius);
	border: 1px solid var(--tooltip-border-color);
	box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.25);
	opacity: 1;
	z-index: 1000;
	padding: var(--spacing-half) var(--spacing-default);
}

.view-agreements .extra-tooltip.place-bottom:before {
	width: 10px;
	height: 10px;
	border: 0;
	transform: rotate(45deg);
	border-top: 1px solid var(--tooltip-border-color);
	border-left: 1px solid var(--tooltip-border-color);
	background-color: var(--tooltip-background-color);
	margin-left: -5px;
	top: -6px;
}

.view-agreements .extra-tooltip.place-bottom:after {
	content: none;
}

.view-agreements .extra-tooltip * {
	color: black;
}

.view-agreements .extra-tooltip a {
	color: var(--primary-color);
	cursor: pointer;
}

.view-agreements .extra-tooltip a:hover {
	text-decoration: underline;
}
