@import url('./assets/fonts/icomoon/style.css');

html {
	line-height: 1.5;
	font-size: 87.5%;
	letter-spacing: 0;
}

p {
	margin-top: initial;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	margin: 0;
	font-weight: 400;
}
