input {
	display: inline-block;
	border: 1px solid var(--menu-link-color);
	height: 30px;
	width: 90px;
	font-size: 20px;
	outline: none;
}

input:required {
	box-shadow: none;
}
input:invalid {
	box-shadow: 0 0 3px white;
}

.button-size {
	display: inline-block;
	border: 1px solid var(--menu-link-color);
	font-size: 14px;
	height: 20px;
	outline: none;
	background-color: transparent;
}

.edit-button__icon {
	padding-right: var(--spacing-default);
}

.edit-button svg {
	fill: currentColor;
}

input[type='text']::-webkit-inner-spin-button,
input[type='text']::-webkit-outer-spin-button {
	background: transparent;
	outline: none;
}

input[type='number'] {
	-moz-appearance: textfield;
}
