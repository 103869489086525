.extra-tooltip {
	max-width: 350px;
	max-height: 350px;
	white-space: normal;
	overflow: hidden;
	overflow-y: auto;
	text-overflow: ellipsis;
	padding: 10px 15px;
}
.extra-tooltip span.icon {
	position: sticky;
	display: none;
	width: 22px;
	height: 22px;
	float: right;
	margin-right: -22px;
}
.extra-tooltip.top-arrow span:nth-child(1) {
	top: 4px;
	display: inline-block;
}
.extra-tooltip.bottom-arrow span:nth-child(2) {
	top: 305px;
	display: inline-block;
}

.extra-tooltip:before,
.extra-tooltip:after {
	content: unset;
}
