.agreement-details::after {
	content: '.';
	visibility: hidden;
	display: block;
	height: 0;
	clear: both;
}
.agreement-details {
	display: flex;
	width: 100%;
}
.agreement-details .agreement-name {
	display: none;
	font-weight: bold;
}
.agreement-details-period {
	width: 100%;
}

.agreement-period {
	font-weight: bold;
}

.tariff-sheet-links {
	white-space: nowrap;
}

.agreement-address-list {
	width: 100%;
	display: flex;
	justify-content: flex-start;
	align-items: stretch;
	flex-wrap: wrap;
	margin: 0 calc(var(--spacing-default) * -1);
}

.agreement-address-list p {
	margin: 0;
}

@media only screen and (max-width: 767px) {
	.agreement-address-list {
		flex-direction: column;
		margin: 0;
	}
	.agreement-details {
		display: block;
	}
	.agreement-details .agreement-name {
		display: block;
	}
	.agreement-period {
		font-weight: normal;
	}
}

.ellipsis-list .address-box__details > *:first-child {
	width: 100%;
	max-height: 29px;
	overflow: hidden;
	line-height: 1rem;
	position: relative;
}

.ellipsis-list .address-box__details > *:first-child > p {
	width: 100%;
}

.ellipsis-list .address-box__details > *:first-child:after {
	content: '...';
	position: absolute;
	bottom: 0;
	right: 0;
	padding: 0 0 0 1em;
	background-size: 100% 100%; /* 512x1 image,gradient for IE9. Transparent at 0% -> white at 50% -> white at 100%.*/
	￿background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAgAAAAABCAMAAACfZeZEAAAABGdBTUEAALGPC/xhBQAAAwBQTFRF////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////AAAA////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////wDWRdwAAAP90Uk5TgsRjMZXhS30YrvDUP3Emow1YibnM9+ggOZxrBtpRRo94gxItwLOoX/vsHdA2yGgL8+TdKUK8VFufmHSGgAQWJNc9tk+rb5KMCA8aM0iwpWV6dwP9+fXuFerm3yMs0jDOysY8wr5FTldeoWKabgEJ8RATG+IeIdsn2NUqLjQ3OgBDumC3SbRMsVKsValZplydZpZpbJOQco2KdYeEe36BDAL8/vgHBfr2CvTyDu8R7esU6RcZ5ecc4+Af3iLcJSjZ1ivT0S/PMs3LNck4x8U7wz7Bv0G9RLtHuEq1TbJQr1OtVqqnWqRdoqBhnmSbZ5mXapRtcJGOc4t2eYiFfH9AS7qYlgAAARlJREFUKM9jqK9fEGS7VNrDI2+F/nyB1Z4Fa5UKN4TbbeLY7FW0Tatkp3jp7mj7vXzl+4yrdsyovx+JYz7mXXNSp/a0RN25JMcLPP8umzRcTZW77tNyk63tdprzXdmO+2zdd9mfe56y9z3lug96mcx02n/CW71JH6Qmf8px/cw77ZvVzB+BCj8D5vxhn/vXZh6D4uzf1rN+Cc347j79q/zUL25TPrJMfG/5LvuNZP8rixeZz/mf+vU+Vut+5nl5gpoeb/sd1dZbTs03hBuvmV5JuaRyMfk849nEM7qnEk6IHI8/qn049hB35QGHiv0yZXuMdkXtYC3ebrglcqvYxoj1muvC1nDlrzJYGbpcdHHIMo2FwYv+j3QAAOBSfkZYITwUAAAAAElFTkSuQmCC);
	background: -webkit-gradient(
		linear,
		left top,
		right top,
		from(rgba(255, 255, 255, 0)),
		to(var(--address-box-background)),
		color-stop(50%, var(--address-box-background))
	);
	background: -moz-linear-gradient(
		to right,
		rgba(255, 255, 255, 0),
		var(--address-box-background) 50%,
		var(--address-box-background)
	);
	background: -o-linear-gradient(
		to right,
		rgba(255, 255, 255, 0),
		var(--address-box-background) 50%,
		var(--address-box-background)
	);
	background: -ms-linear-gradient(
		to right,
		rgba(255, 255, 255, 0),
		var(--address-box-background) 50%,
		var(--address-box-background)
	);
	background: linear-gradient(
		to right,
		rgba(255, 255, 255, 0),
		var(--address-box-background) 50%,
		var(--address-box-background)
	);
}

.ellipsis-list .address-box__details > *:first-child > p:after {
	content: '\0000a0';
	position: absolute;
	width: 1000px;
	z-index: 1;
	background: var(--address-box-background);
}
