.download-link {
	display: inline-block;
	color: inherit;
	text-decoration: none;
}
.download-link > .icon {
	vertical-align: middle;
}
.download-link > .icon svg {
	fill: currentColor;
}
.download-link > .icon:not(:only-child) {
	margin-right: calc(var(--spacing-half) * 0.5);
}
