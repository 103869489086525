.cognito-require-new-password {
	width: 400px;
	margin: 100px auto;
	padding-bottom: 20px;
}

.cognito-require-new-password .logo,
.cognito-require-new-password .title {
	text-align: center;
}
.cognito-require-new-password .logo {
	margin-bottom: 80px;
}

.cognito-require-new-password .logo img {
	max-width: 300px;
	width: 100%;
}

.cognito-require-new-password h1 {
	font-size: 28px;
	font-weight: 300;
}

.cognito-require-new-password input {
	width: 100%;
	padding: 10px;
	height: 40px;
	border-radius: 5px;
	font-size: 14px;
	background-color: #e0e0e0;
	border: 1px solid #e0e0e0;
	transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
	box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}

.cognito-require-new-password button.signin {
	width: 100%;
	height: 40px;
	background-color: var(--primary-color);
	color: #ffffff;
}
.cognito-require-new-password input:focus {
	border: 1px solid var(--primary-color);
	box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
		0 0 8px rgba(102, 175, 233, 0.6);
	outline: 0;
}
.cognito-require-new-password .forgot-password {
	display: block;
	cursor: pointer;
	font-size: small;
	margin: 10px 0 0 10px;
	color: var(--secondary-color);
}

.cognito-require-new-password .new-password-field {
	text-align: center;
}
.cognito-require-new-password footer {
	position: fixed;
	bottom: 0;
	left: 0;
	width: 100%;
	padding: 30px;
	text-align: center;
	color: var(--secondary-color);
	border-top: 1px solid rgba(0, 0, 0, 0.1);
	background-color: rgba(255, 255, 255, 1);
}

.cognito-require-new-password button[disabled] {
	color: var(--disabled-text-color);
	cursor: not-allowed;
}

.passwords-not-match {
	color: red;
}

.cognito-require-new-password .lang-switch-navigation {
	position: fixed;
	top: 0;
	right: 10px;
}

@media only screen and (max-width: 767px) {
	.cognito-require-new-password {
		width: 70%;
		margin: 100px auto;
	}
}
