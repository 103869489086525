.my-participation ul {
	list-style-type: none;
	padding-left: 0;
	margin: 0;
}

.my-participation ul li {
	display: grid;
	grid-template-columns: 55% 45%;
	margin-bottom: 10px;
}

.my-participation ul li span.label {
	justify-self: start;
	max-width: 100%;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}
.my-participation ul li span.value {
	font-weight: bold;
	justify-self: flex-end;
	max-width: 100%;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.my-participation .period {
	padding-bottom: 5px;
	border-bottom: 1px solid #ccc;
	margin-bottom: 10px;
	margin-top: 40px;
}

.peak-values {
	display: flex;
	justify-content: space-around;
}
.normal-value {
	float: left;
}
.peak-values span,
.normal-value span {
	font-weight: bold;
}
