.meter-measurements-gas {
	width: 100%;
	text-align: center;
}
.meter-measurements-gas th,
.meter-measurements-gas td {
	width: 20%;
}
.meter-measurements-gas th:first-child,
.meter-measurements-gas td:first-child {
	text-align: left;
}
