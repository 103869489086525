.accordion {
	padding-top: 20px;
}

.accordion__item {
	background-color: #fff;
	margin-bottom: var(--spacing-default);
	border-radius: var(--border-radius);
	-webkit-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.25);
	box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.25);
	position: relative;
}

.accordion__item--has-icon {
	position: relative;
}

.accordion__title {
	padding: 0 20px;
	outline: none;
	display: flex;
	align-items: center;
	min-height: 50px;
	cursor: pointer;
	width: 100%;
	text-align: left;
	border: none;
}

.accordion__title i.material-icons,
.accordion__title span.icon--expandless {
	transition: transform 0.25s ease-in-out;
	transform-origin: center center;
}
.accordion__title.collapsed i.material-icons,
.accordion__title.collapsed span.icon--expandless {
	transform: rotate(-180deg);
}

.accordion__title h3 {
	flex-grow: 2;
	font-weight: bold;
}

.accordion__body {
	padding: 20px 0;
	margin: 0 20px;
	display: block;
	background-color: #fff;
	border-top: 1px solid var(--collapsible-header-border-color);
}

.accordion__body--hidden {
	display: none;
	opacity: 0;
}

.accordion__title > *:last-child,
.accordion__body > *:last-child {
	margin-bottom: 0;
}

span.icon {
	margin-right: 10px;
}
