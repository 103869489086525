.cognito-signin {
	width: 400px;
	margin: 100px auto;
	padding-bottom: 20px;
}

.cognito-signin .logo,
.cognito-signin .title {
	text-align: center;
}
.cognito-signin .logo {
	display: flex;
	justify-content: center;
	margin-bottom: 80px;
	cursor: pointer;
}

.cognito-signin .logo img {
	max-width: 300px;
	max-height: 300px;
}

.cognito-signin h1 {
	font-weight: 300;
}

.cognito-signin input {
	width: 100%;
	padding: 10px;
	height: 40px;
	border-radius: 5px;
	font-size: 14px;
	background-color: #e0e0e0;
	border: 1px solid #e0e0e0;
	transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
	box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}

.cognito-signin button.signin {
	width: 100%;
	min-height: 40px;
	background-color: var(--primary-color);
	color: #ffffff;
}
.cognito-signin input:focus {
	border: 1px solid var(--primary-color);
	box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
		0 0 8px rgba(102, 175, 233, 0.6);
	outline: 0;
}

.cognito-signin .forgot-password,
.cognito-signin .back-to-signin,
.cognito-signin .reactivate-account {
	display: block;
	cursor: pointer;
	font-size: small;
	margin: 10px 0 0 10px;
	color: var(--secondary-color);
}

.cognito-signin footer {
	position: fixed;
	bottom: 0;
	left: 0;
	width: 100%;
	padding: 30px;
	text-align: center;
	color: var(--secondary-color);
	border-top: 1px solid rgba(0, 0, 0, 0.1);
	background-color: rgba(255, 255, 255, 1);
}

.cognito-signin .lang-switch-navigation {
	position: fixed;
	top: 0;
	right: 10px;
}

.cognito-signin .fieldset.impersonator input {
	width: 71%;
	float: right;
	padding-left: 5px;
	border-bottom-left-radius: 0;
	border-top-left-radius: 0;
}

.cognito-signin .fieldset.impersonator .input-group-prefix {
	position: absolute;
	top: 0.75rem;
	padding-left: 10px;
	height: 40px;
	background: var(--address-box-background);
	padding-top: 6px;
	width: 29%;
	border-bottom-left-radius: 5px;
	border-top-left-radius: 5px;
	border: 1px solid var(--input-border-color);
}

.cognito-signin .back-to-signin {
	display: block;
	cursor: pointer;
	font-size: small;
	margin: 10px 0 0 10px;
	color: var(--secondary-color);
}

@media only screen and (max-width: 767px) {
	.cognito-signin {
		width: 70%;
		margin: 70px auto;
		padding-bottom: 30px;
	}

	.cognito-signin .logo {
		margin-bottom: 40px;
	}

	.cognito-signin .logo img {
		width: 80%;
	}

	.cognito-signin footer {
		padding: 15px;
	}
}
