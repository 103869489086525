.move-wizard-step-2 {
	.fieldset--is-required {
		.fieldset__label-text::after {
			display: none;
		}
		.fieldset__error-message {
			color: #fff;
		}
	}
	.card-0, .card-1 {
			@media only screen and (max-width: 767px) {
				display: flex;
				justify-content: center;
			}
		}
	.old-address, .new-address {
		margin-left: 35px;
		margin-bottom: 10px;
	}
	.start-date, .end-date {
		width: 270px;
		.error {
			padding-left: 35px;
			display: block;
		}
		.field-label {
			margin-left: 35px;
			width: 170px;
			display: flex;
			justify-content: space-between;
			align-items: center;
			span {
				font-size: 10px;
			}
		}

		h3 {
			display: flex;
    	align-items: center;
			margin-bottom: 10px;
		}
	}
	.end-date {
		.icon svg {
			fill: black;
		}
	}
}

