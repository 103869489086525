.fieldset {
	border: none;
	padding: 0;
	margin: 1rem 0;
	position: relative;
	padding-top: 0.75rem;
}
.fieldset[disabled],
.fieldset[disabled] input {
	color: var(--disabled-text-color);
}

.fieldset[disabled] input {
	background-color: #f4f4f4;
}

.fieldset__error-message {
	color: var(--error-color);
}
.fieldset__label {
	display: inline-block;
}
.fieldset--has-focus .fieldset__label,
.fieldset--has-value .fieldset__label {
	transform: translateY(-85%) scale(0.75);
}
.fieldset--is-required .fieldset__label-text:after {
	content: ' *';
	font-size: 0.85em;
}
.fieldset--is-required.fieldset--has-value.fieldset--valid
	.fieldset__label:after {
	content: none;
}
.fieldset__label {
	padding: 0 0 5px 10px;
	position: absolute;
	top: 1.1em;
	font-size: 1rem;
	transform-origin: left top;
	transition: transform 0.15s ease-in-out;
	z-index: 1;
	pointer-events: none;
}

.text-area-placeholder .fieldset__label-text {
	color: #b1b1b1;
}

.fieldset--has-value.text-area-placeholder .fieldset__label-text,
.fieldset--has-focus.text-area-placeholder .fieldset__label-text {
	color: var(--default-text-color);
}
