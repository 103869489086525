.overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: var(--z-index-high);
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
.overlay:before {
	content: '';
	position: fixed;
	display: block;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	background-color: black;
	opacity: 0.65;
	z-index: var(--z-index-low);
}

.overlay .panel {
	border-radius: var(--border-radius);
}

.overlay__wrapper {
	width: calc(100% - (0.775rem * 2));
	max-width: var(--max-width);
	max-height: calc(100% - (var(--spacing-half) * 2));
	overflow-y: visible;
	position: relative;
}
.overlay__content {
	position: relative;
	z-index: var(--z-index-medium);
	display: flex;
	flex-direction: column;
	justify-content: center;
}
.overlay__close-button {
	height: var(--header-height);
	background-color: transparent;
	border: 0;
	padding: 0;
	margin-right: var(--spacing-double);
	position: absolute;
	right: 0;
	z-index: 10;
}
.overlay__close-icon svg {
	fill: black;
}

@media only screen and (max-width: 767px) {
	.overlay__wrapper {
		width: calc(100% - (var(--spacing-half) * 2));
	}
}
