.panel {
	background-color: #ffffff;
	border-radius: var(--border-radius);
	box-shadow: 0 2px 5px rgba(0, 0, 0, 0.26);
	display: flex;
	flex-direction: column;
	height: 100%;
}
.panel--transparent {
	background-color: transparent;
	box-shadow: none;
}
.panel__content {
	padding: var(--spacing-double);
	flex-grow: 1;
	flex-shrink: 1;
	flex-basis: auto;
	overflow-x: unset;
}
