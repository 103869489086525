.move-wizard-step-1 {
	.move-wizard__section .title {
		display: flex;
		align-items: baseline;
		.spinner-component {
			position: relative;
			top: 5px;
			left: 10px;
		}
	}
	.address-wrapper {
		display: flex;
		justify-content: space-between;
		align-items: center;
		background: var(--table-even-row-background-color);
		padding: 15px 20px;
		border: 1px solid var(--primary-color);
		color: black;
		.address {
			display: flex;
			align-items: center;
		}
		svg {
			fill: var(--primary-color);
		}
	}
	form {
		margin-top: 30px;
	}
	.address-fields-input {
		.extension-dropdown {
			position: relative;
			width: 150px;
			min-width: 50px;
			height: 32px;
			margin-top: 7px;
			margin-left: 10px;
			float: left;
			@media only screen and (max-width: 767px) {
				width: 20%;
			}
			.additions {
				.additions__control {
					position: relative;
					height: 32px;
					min-height: 32px;
					border-color: #ccc;
				}
				.additions__input,
				.additions__value-container,
				.additions__indicators-container,
				.additions__placeholder {
					height: 32px;
				}
				.additions__placeholder {
					color: var(--default-text-color);
					white-space: nowrap;
					top: 62%;
				}
				.additions__single-value {
					top: 60%;
				}
				&.additions--is-disabled .additions__placeholder {
					color: var(--disabled-text-color);
				}
				.additions__menu {
					padding: 2px 3px;
					.additions__option:hover {
						cursor: pointer;
					}
				}
				.additions__singleValue {
					top: 60%;
				}
				.additions__indicator-separator {
					display: none;
				}
				.additions__indicators {
					position: absolute;
					top: auto;
					z-index: 1;
					right: 0;
				}
				.additions__indicator {
					position: relative;
    			top: -3px;
				}
			}
		}
		.error-message, .multiple-addition {
			margin-top: 10px;
			clear: both;
		}
	}
	.address-field {
		display: flex;
		align-items: center;
		&.house_number {
			float: left;
		}
		label {
			width: 115px;
		}
		fieldset {
			padding-top: 0;
    	margin-bottom: 10px;
			input {
				background: #fff;
			}
		}
	}
	.accepted-address-wrapper {
		.accepted-address {
			label {
				display: flex;
				align-items: center;
			}
			.connection-icons {
				display: flex;
				flex-direction: column;
			}
		}
		.connections {
			.title {
				margin-bottom: 10px;
			}
			span.icon {
				margin-right: 0;
			}
		}
		.connection {
			display: flex;
			align-items: center;
			padding-bottom: 5px;
			font-size: 12px;
		}
		.accepted-address-text {
			font-weight: bold;
			padding: 10px 0 25px 35px;
			span {
				display: block;
			}
		}
	}
	.fieldset {
		&.house_number input {
			width: 50px;
		}
		&.zip_code input {
			width: 70px;
		}
	}
}

.checkbox {
	margin: 15px 0;
	label {
		display: flex;
		align-items: center;
		padding-left: 25px;
	}

	input[type="checkbox"] {
  	display: none;
	}
	input[type="checkbox"] {
		~ span {
			position: absolute;
			left: 0;
			svg {
				width: 20px;
			}
			&.checked {
				display: none;
				svg {
					fill: var(--primary-color);
				}
			}
			&.unchecked {
				svg {
					fill: var(--border-gray-color);
				}
			}
		}
		&:checked ~ span{
			&.checked {
				display: block;
			}
			&.unchecked {
				display: none;
			}
		}
	}
}

.move-wizard-step-1 form h3 {
	margin-bottom: 25px;
}

.move-wizard-step-1 .error-message {
	color: var(--error-color);
}

.move-wizard-step-1 .move-wizard__section {
	position: relative;
}

@media only screen and (max-width: 767px) {

	.move-wizard__section .spinner-component {
		left: unset;
	}
}
