.panel-header h3 {
	display: flex;
}

.energy-info header h3 {
	display: flex;
	font-weight: bold;
}

.energy-info h3 {
	text-align: center;
	font-weight: bold;
	margin-bottom: 15px;
}

.energy-info ul {
	list-style-type: none;
	padding-left: 0;
	margin: 0;
}

.energy-info ul li {
	display: grid;
	grid-template-columns: 50% 50%;
	grid-column-gap: 10px;
	margin-bottom: 10px;
}
.energy-info ul li span.label {
	justify-self: start;
	max-width: 100%;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}
.energy-info ul li span.value {
	font-weight: bold;
	justify-self: flex-end;
	max-width: 100%;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.unit.kw {
	padding-right: 10px;
}

.energy-info ul li span.value > span > span:first-child {
	text-align: right;
	width: 50px;
	display: inline-block;
}
