.card-header {
	display: flex;
	align-content: center;
	align-items: center;
	min-height: 50px;
	width: 100%;
	padding: var(--spacing-default) var(--spacing-double);
	background-color: transparent;
	justify-content: space-between;
	align-items: center;
	border: 0;
	position: relative;
	outline: none;
	background-color: var(--address-box-background);
	font-weight: bold;
}

.card-header i {
	color: rgba(0, 0, 0, 0.54);
}

@media only screen and (max-width: 767px) {
	.card-header {
		padding: var(--spacing-default) var(--spacing-default);
	}
}
