.dashboard {
	position: relative;
	max-width: var(--max-width);
	margin-left: auto;
	margin-right: auto;
}

.dashboard .panel-header h3 {
	display: flex;
	align-items: center;
}

.dashboard-tiles {
	display: grid;
	grid-template-columns: 32% 32% 32%;
	justify-content: center;
	grid-column-gap: 2%;
	grid-row-gap: 20px;
	grid-auto-rows: 1fr;
	margin: 0 calc(var(--spacing-default) * -1);
}

.accordion__item .customer-service button.button {
	position: static;
}

@media (max-width: 1024px) {
	.dashboard-tiles {
		grid-template-columns: 40% 40%;
	}
}
