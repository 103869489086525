.currency-stepper > * {
	display: inline-block;
	vertical-align: middle;
}

.currency-stepper {
	display: inline-block;
}

.currency-stepper__button {
	-webkit-appearance: none;
	padding: 0;
	border: 0;
	background: none;
}
.currency-stepper__button[disabled] svg {
	fill: var(--disabled-text-color);
}

@media only screen and (min-device-width: 320px) and (max-device-width: 375px) {
	.currency-stepper {
		padding-right: 10%;
	}

	.currency-stepper__button {
		padding: 10%;
	}
}
