.slider {
	position: relative;
	height: var(--slider-track-height);
	width: calc(100% + var(--slider-extra-width));
	transform: translateX(calc(var(--slider-extra-width-half) * -1));
}
.slider:after {
	content: '';
	background-color: var(--menu-border-color);
	width: calc(100% - var(--slider-extra-width));
	height: var(--slider-track-height);
	display: block;
	position: absolute;
	top: 0;
	left: var(--slider-extra-width-half);
	z-index: -1;
}

.slider__input {
	position: absolute;
	top: 0;
	width: 100%;
	height: var(--slider-total-height);
	margin: 0;
	transform: translateY(calc(-50% + var(--slider-track-height-half)));
	opacity: 0;
}

.slider__track {
	height: var(--slider-total-height);
	width: 100%;
	transform: translateY(calc(-50% + var(--slider-track-height-half)));
	position: relative;
	overflow: hidden;
}

.slider__handle {
	display: block;
	height: var(--slider-total-height);
	width: calc(100% - var(--slider-extra-width));
	position: absolute;
	left: var(--slider-extra-width-half);
}

.slider__handle-circle {
	position: absolute;
	width: var(--slider-total-height-half);
	height: var(--slider-total-height-half);
	transform: translateX(-50%);
	top: calc(var(--slider-total-height-half) / 2);
	border-radius: 50%;
}

.slider-value {
	position: relative;
	width: 100px;
	height: 100px;
}

.slider--is-focussed .slider__handle-circle {
	box-shadow: 0px 0px 3px 0.25rem Highlight;
	box-shadow: 0px 0px 3px 0.25rem -webkit-focus-ring-color;
}
