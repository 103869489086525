.view-contact__label {
	margin-bottom: 0;
}

.view-contact__label + p {
	margin-bottom: 1em;
}

.contact-form {
	display: flex;
	align-items: stretch;
	flex-direction: column;
	box-shadow: 0 2px 5px rgba(0, 0, 0, 0.26);
	border-radius: 5px;
	margin-bottom: 45px;
}

.contact-form > * {
	display: flex;
	align-items: stretch;
}

.contact-form > * .panel {
	width: 100%;
	box-shadow: none;
	padding: 0;
	margin: 0;
}

.contact-form .fieldset--has-focus .fieldset__label,
.contact-form .fieldset--has-value .fieldset__label {
	-webkit-transform: translateY(-100%) scale(0.75);
	-ms-transform: translateY(-100%) scale(0.75);
	transform: translateY(-100%) scale(0.75);
}

.contact-form > * .panel .relation-summary .panel__content {
	padding: 0;
}

.contact-form .panel__content {
	overflow-y: visible;
	overflow-x: visible;
	padding-top: 20px;
}

.contact-form > * .panel .relation-summary .panel__content p {
	padding: var(--spacing-half);
	background-color: var(--highlight-color);
	font-size: 1.5em;
	margin-bottom: 20px;
}

.contact-form > * .panel .relation-summary .panel__content .description-list {
	font-size: 1.2em;
	padding: 0 var(--spacing-half);
}

.contact-form > * .panel .relation-summary .panel__content .description-list dt,
.contact-form
	> *
	.panel
	.relation-summary
	.panel__content
	.description-list
	dd {
	padding: var(--spacing-half) 0;
	margin-bottom: 20px;
	border-bottom: 1px solid rgba(0, 0, 0, 0.15);
	opacity: 1;
}

.contact-form
	> *
	.panel
	.relation-summary
	.panel__content
	.description-list
	dt {
	color: rgba(0, 0, 0, 0.5);
}

.contact-form > * .panel .panel__content {
	display: inline-block;
}
.contact-form .email div {
	overflow: unset;
}
.contact-form > * .panel .panel__content .email {
	padding-left: 0;
}

.contact-form > * .panel .panel__content .phonenumber,
.contact-form > * .panel .panel__content .email {
	padding-right: 25px;
}
.contact-form > * .panel .panel__content .phonenumber a,
.contact-form > * .panel .panel__content .email a {
	text-decoration: none;
	color: var(--primary-color);
}
.contact-form > * .panel .panel__content .phonenumber a {
	position: relative;
	top: -8px;
}

.contact-info .panel-header-inner {
	padding-bottom: 0;
}

.contact-form > * .panel .panel__content .email a {
	margin-top: -5px;
}

.contact-form > * .panel .panel__content .email,
.contact-form > * .panel .panel__content .number-and-time {
	padding: var(--spacing-half);
	display: flex;
	align-items: center;
}

.contact-form .ask-a-question__submit {
	box-shadow: none;
	border: none;
	outline: none;
	padding: 0;
	text-transform: capitalize;
	color: var(--primary-color);
	font-size: 16px;
}
.contact-form .ask-a-question__submit[disabled] {
	color: var(--disabled-text-color);
}
.contact-form dl.opening-hours__times {
	display: flex;
	flex-wrap: nowrap;
	margin-top: 7px !important;
}
.contact-form .opening-hours__times dt,
.contact-form .opening-hours__times dd {
	white-space: nowrap;
	display: inline !important;
}

.contact-form .panel.label-contact {
	padding: 0 25px;
	border-radius: 0 0 5px 5px;
}

.contact-form .panel.label-contact .panel-header-inner {
	padding-left: 0;
}

.contact-form .panel.label-contact .panel__content {
	display: flex;
	flex-direction: row;
	flex-grow: 0;
	padding: 0;
}
.contact-form > * .panel .panel__content .number-and-time > * {
	flex: 1;
}

.contact-form > * .panel .panel__content .phonenumber {
	display: flex;
	align-items: center;
}

.contact-form > * .panel .panel__content .time {
	display: inline-flex;
}

.contact-form > * .panel .panel__content .email svg,
.contact-form > * .panel .panel__content .phonenumber svg,
.contact-form > * .panel .panel__content .time svg {
	width: 32px;
	max-height: 32px;
	position: static;
	transform: none;
	height: auto;
	display: flex;
}

.contact-form > * .panel .panel__content .email .icon,
.contact-form > * .panel .panel__content .phonenumber .icon,
.contact-form > * .panel .panel__content .time .icon {
	padding: 0;
}

.contact-form > * .panel .panel__content > * .icon {
	margin-right: 10px;
	display: inline-flex;
	align-items: center;
	pointer-events: none;
}

.contact-form > * .panel .panel__content .time .description-list {
	display: inline-block;
	align-items: center;
}

.contact-form > * .panel .panel__content .time .description-list:last-child {
	margin: 0;
}

.contact-form > * .panel .panel__content .time .description-list > * {
	max-width: 100%;
	min-width: 0;
	padding: 0;
	display: flex;
	justify-content: center;
	flex: 1;
}

.contact-form > * .panel .panel__content .time .description-list dt:after {
	content: ':';
	margin: 0 4px 0 0;
}

.contact-form > * .panel .panel__content .time .description-list dd {
	display: inline-flex;
	flex: none;
}

.contact-form__ask-form {
	background-color: #fff;
	position: relative;
	padding: var(--spacing-half);
	border-radius: 5px 5px 0 0;
}

.contact-form__ask-form .fieldset {
	padding-top: 0;
	background-color: #fff;
}

.contact-form__ask-form .fieldset:first-child {
	margin-top: 0;
}

.contact-form__ask-form .select {
	width: 40%;
}
.contact-form__ask-form .select select {
	padding-left: var(--spacing-half);
}

.contact-form__ask-form .fieldset__label {
	left: var(--spacing-half);
	top: 0.25em;
	padding: 0;
}
.contact-form__ask-form .input {
	width: 95%;
}
.contact-form__ask-form .input textarea {
	border-radius: 4px;
	padding: var(--spacing-half);
	resize: vertical;
	min-height: 185px;
}

@media (max-width: 767px) {
	.contact-form .panel.label-contact .panel__content {
		flex-direction: column;
	}
	.contact-form > * .panel .panel__content .email {
		padding-left: 0;
	}
	.contact-form > * .panel .panel__content .number-and-time {
		flex-direction: column;
		align-items: flex-start;
		padding-left: 0;
	}
	.contact-form > * .panel .panel__content .number-and-time .phonenumber {
		padding-bottom: 18px;
	}
	.contact-form__ask-form .select {
		width: 100%;
	}
	.contact-form__ask-form .input {
		width: 100%;
	}
	.contact-form .panel.label-contact {
		padding: 0 20px 35px 20px;
	}
	.contact-form .panel__content {
		padding-left: 15px;
		padding-right: 15px;
	}
	.contact-form .ask-a-question__submit {
		margin-right: 0;
	}
}
