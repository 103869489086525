.connection-links-tooltip {
	display: flex;
}
.connection-link span:after {
	content: '|';
	margin: 0 10px;
}
.connection-link span:last-child:after {
	content: '';
	margin: 0;
}
