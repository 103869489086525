.agreement-box-wrapper {
	border: 1px solid #ccc;
	min-height: 70px;
	padding: 15px;
	margin-bottom: 20px;
	transition: all 0.5s linear;
	&:not(.multiple-address):hover {
		border: 1px solid var(--primary-color);
		cursor: pointer;
		.agreement-box .radio-button {
			border: 1px solid var(--primary-color);
		}
	}
	&.selected {
		border: 1px solid var(--primary-color);
		.agreement-box .radio-button {
			border: 1px solid var(--primary-color);
			background-color: var(--primary-color);
			box-shadow: inset 0 0 0 2px #fff;
		}
	}
	&.multiple-address .agreement-box {
		align-items: baseline;
		.agreement-id {
			position: relative;
			top: 5px;
			margin: 0 10px 0 35px;
		}
	}
	.agreement-box.duplicates {
		border: 1px solid var(--collapsible-header-border-color);
		background-color: var(--address-box-background);
	}
	.multiple-address-info {
		min-height: 70px;
		border: 1px solid #ccc;
		border-left: 5px solid var(--primary-color);
		padding: 5px 10px;
		margin-bottom: 15px;
		display: flex;
		align-items: baseline;
		.icon {
			position: relative;
			top: -5px;
			svg {
				fill: var(--primary-color);
			}
		}
		a {
			color: var(--primary-color);
		}
	}
	.agreement-box {
		display: flex;
		align-items: center;
		.address-list {
			flex-grow: 2;
			display: flex;
			flex-direction: column;
		}
		.agreement-info {
			display: flex;
			justify-content: space-between;
			padding: 10px 0;
			.address {
				display: flex;
				align-items: flex-start;
				width: 45%;
				color: var(--primary-color);
				.icon {
					margin: 11px 0 0 0;
				}
			}
			.additional-info {
			width: 45%;
			}
			&:not(:first-child) {
				border-top: 1px solid #ccc;
			}
		}
		.agreement-id {
			margin: 0 10px;
		}
		.radio-button {
			width: 15px;
			height: 15px;
			border: 1px solid #ccc;
			border-radius: 50%;
			margin-right: 10px;
		}
		.address-list {
			margin-left: 30px;
		}
		.address, .connection-icons {
			.icon svg {
				fill: var(--primary-color);
			}
		}
		.address-text {
			display: flex;
			flex-direction: column;
		}
		.connection-icons {
			width: 10%;
			display: flex;
			align-items: center;
			justify-content: flex-end;
			span.icon {
				display: flex;
				justify-content: flex-end;
			}
		}
	}
	.my-node-enter {
			opacity: 0;
		}
	.my-node-enter-active {
		opacity: 1;
		transform: translateX(0);
		transition: opacity 300ms, transform 300ms;
	}
	.my-node-exit {
		opacity: 1;
	}
	.my-node-exit-active {
		opacity: 0;
		transform: scale(0.9);
		transition: opacity 300ms, transform 300ms;
	}

	.showDuplicates {
		color: #fff;
		padding: 5px 10px;
		background-color: var(--primary-color);
		text-transform: uppercase;
		font-size: small;
		border: none;
		float: right;
		margin: 15px 0 5px 0;
		outline: none;
	}
}

@media (max-width: 767px) {
	.agreement-box-wrapper {
		padding-bottom: 0;
		&.multiple-address .agreement-box {
			.agreement-id, .address-list {
				margin-left: 0;
			}
		}
		.agreement-box {
			display: block;
			align-items: unset;
			padding-left: 5px;
			.radio-button {
				float: left;
				position: relative;
				top: 2px;
			}
			.address-list {
				margin-left: 23px;
			}
			.agreement-info {
				flex-direction: column;
				.address {
					margin-bottom: 10px;
					width: unset;
				}
				.additional-info {
					width: unset;
					flex-direction: column;
					padding-left: 18px;
				}
				.connection-icons {
					width: unset;
					position: relative;
					top: -30px;
				}
			}
		}
	}
}
