.move-wizard-step-3 {
	.move-wizard-step-3__section {
		padding: var(--spacing-double) var(--spacing-default);
	}
	.info-message {
		display: flex;
		align-items: center;
		color: var(--primary-color);
		margin-bottom: 10px;
		.icon {
			svg {
			 fill: var(--primary-color)
		 	}
		}
	}
	.no-end-date-info {
		padding-left: 30px;
	}
	.move-in, .move-out {
		.delivery, .address, .no-end-date {
			padding-left: 35px;
		}
		.address {
			margin-top: 10px;
		}
		.title {
			display: flex;
			align-items: center;
		}
	}
	.move-out {
		.icon svg {
			fill: black;
		}
	}
}
