.error-text {
	color: var(--error-color);
	display: block;
	text-align: center;
}
.error-text--size-small {
	font-size: 0.8em;
}
.error-text--align-left {
	text-align: left;
}
.error-text--align-right {
	text-align: right;
}
