.slider-labeled {
	margin-top: 5%;
	padding: 0 0.5rem;
	margin-left: 10%;
	margin-right: 10%;
	z-index: var(--z-index-low);
}

.slider-labeled .slider {
	z-index: var(--z-index-medium);
}

.slider-labeled__markers {
	position: relative;
	display: flex;
}
.slider-labeled__markers--top {
	margin-bottom: 13px;
}
.slider-labeled__markers--bottom {
	margin-top: 13px;
}
.slider-labeled__marker {
	position: relative;
	background-color: transparent;
	border-color: #ccc;
	border-width: 0;
	border-style: solid;
	font-size: 0.9em;
	display: flex;
	flex-direction: column;
	transform: translateX(-50%);
}
.slider-labeled__marker:before {
	content: '';
	width: 1px;
	height: 13px;
	position: absolute;
	top: 100%;
	border: none;
	left: 50%;
	background-color: #ccc;
}
.slider-labeled__marker--line-start {
	transform: none;
	border-left: 1px solid #ccc;
}
.slider-labeled__marker--line-start:before {
	right: auto;
	left: 0;
	transform: translateX(-1px);
}
.slider-labeled__marker--line-end {
	transform: none;
	border-right: 1px solid #ccc;
}
.slider-labeled__marker--line-end:before {
	right: 0;
	left: auto;
	transform: translateX(1px);
}
.slider-labeled__marker--min:before,
.slider-labeled__marker--max:before {
	top: auto;
	bottom: 100%;
}
.slider-labeled__marker--max {
	margin-left: auto;
}
.slider-labeled__marker .currency {
	padding-left: 0.25rem;
	padding-right: 0.25rem;
}
.slider-labeled__marker-block {
	padding: 0.3em 0.4em;
	display: flex;
	flex-direction: column;
	border: none;
	cursor: pointer;
}
.slider-labeled__marker-block--right {
	transform: translateX(calc(-50% + 2px));
}
.slider-labeled__marker-block--left {
	transform: translateX(calc(50%));
}
.slider-labeled__marker-block {
	padding: 0.3em 0.4em;
	display: flex;
	flex-direction: column;
}
.slider-labeled__marker-block--right {
	transform: translateX(calc(-50% + 2px));
}
.slider-labeled__marker-block--left {
	transform: translateX(calc(50%));
}
.slider-labeled__marker-block {
	padding: 0.3em 0.4em;
	display: flex;
	flex-direction: column;
}
.slider-labeled__marker-block--right {
	transform: translateX(calc(-50% + 2px));
}
.slider-labeled__marker-block--left {
	transform: translateX(calc(50%));
}

.slider-labeled__marker--center {
	align-self: center;
}
