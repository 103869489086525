.collapsible {
	margin-bottom: var(--spacing-default);
	border-radius: var(--border-radius);
	box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.25);
	position: relative;
}

.collapsible .spinner-component {
	width: 100%;
	height: 100%;
	min-height: 100px;
	position: static;
}

.collapsible .panel {
	box-shadow: none;
	border-radius: var(--border-radius);
}

.collapsible__header {
	position: relative;
}

.collapsible--expanded .collapsible__header:after {
	content: '';
	display: block;
	left: 2px;
	right: 2px;
	border-bottom: 1px solid var(--collapsible-header-border-color);
	position: absolute;
}

.collapsible__header-background {
	position: absolute;
	width: 100%;
	height: 100%;
	border-radius: 5px;
}
.collapsible__header-background:after {
	content: '';
	position: absolute;
	width: 100%;
	height: 100%;
	background-color: transparent;
	opacity: 0.1;
}

.collapsible__header-button {
	display: flex;
	align-content: center;
	align-items: center;
	min-height: 50px;
	width: 100%;
	padding: var(--spacing-default) calc(var(--spacing-default) * 1.5);
	background-color: transparent;
	justify-content: space-between;
	border: 0;
	position: relative;
	outline: none;
	cursor: pointer;
}

.collapsible__header-button > h2,
.collapsible__header-button > h3 {
	flex: 1;
	display: flex;
	align-content: center;
	align-items: center;
	font-weight: bold;
}

.collapsible__content {
	display: none;
	background-color: transparent;
	padding: var(--spacing-default) calc(var(--spacing-default) * 1.5);
}

.collapsible--expanded > .collapsible__content,
.collapsible:target .collapsible__content {
	display: block;
}
.collapsible--expanded > .collapsible__header > .collapsible__header-button > i,
.collapsible:target .collapsible__header-button > i {
	transform: rotate(180deg);
}

.collapsible > .collapsible__header > .collapsible__header-button > i {
	transition: transform 0.25s ease-in-out;
	transform-origin: center center;
}
