.primary-text-color {
	color: var(--primary-color);
}

.primary-bg-color {
	background-color: var(--primary-color);
}

.transparent-bg-color {
	background-color: transparent;
}

.primary-icon svg {
	fill: var(--primary-color);
}

.secondary-text-color {
	color: var(--secondary-color);
}

.font-family-web {
	font-family: var(--font-family-web);
}

.secondary-bg-color {
	background-color: var(--secondary-color);
}

.secondary-icon svg {
	fill: var(--secondary-color);
}

.tertiary-text-color {
	color: var(--tertiary-color);
}

.tertiary-bg-color {
	background-color: var(--tertiary-color);
}

.tertiary-icon svg {
	fill: var(--tertiary-color);
}

.highlight-text-color {
	color: var(--highlight-color);
}

.highlight-bg-color {
	/*background-color: #F4F5F6;*/
	background-color: var(--highlight-color);
}

.highlight-icon svg {
	fill: var(--highlight-color);
}

.light-bg-color {
	background-color: white;
}
