.participation-title {
	width: 100%;
	display: flex;
	font-weight: bold;
	clear: both;
	font-size: 14px;
}

.participation-value {
	width: 100%;
	display: flex;
	clear: both;
	font-size: 15px;
}

.widgets {
	display: grid;
	grid-template-columns: 32% 32% 32%;
	justify-content: center;
	grid-column-gap: 2%;
	grid-row-gap: 20px;
	grid-auto-rows: 1fr;
	padding-bottom: 20px;
}

.view-sustainable .icon svg {
	fill: var(--secondary-color);
}
@media (max-width: 1024px) {
	.widgets {
		grid-template-columns: 40% 40%;
	}
}
