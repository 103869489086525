.main-navigation {
	display: flex;
	justify-content: center;
}

.main-navigation:before {
	content: '';
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100%;
	background: #000;
	visibility: hidden;
	opacity: 0;
	transition: opacity 0.3s 0.2s, visibility 0s 0.5s;
	z-index: var(--z-index-medium);
}

.main-navigation__menu {
	display: flex;
	flex-direction: column;
	list-style-type: none;
	margin-top: 0;
	padding-left: 0;
}

@media all and (-ms-high-contrast: none) {
	.main-navigation .main-navigation__drawer {
		left: auto;
		right: auto;
		transform: translateX(-50%);
	} /* IE10 */
	*::-ms-backdrop,
	.main-navigation .main-navigation__drawer {
		left: auto;
		right: auto;
		transform: translateX(-50%);
	} /* IE11 */
}

.main-navigation__drawer {
	background-color: #fff;
	z-index: var(--z-index-high);
	transform: translateX(-100%);
	visibility: hidden;
	transition: transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1),
		visibility 0s 0.3s;
	overflow: auto;
	margin-right: 3%;
	position: fixed;
	height: 100%;
	top: 0;
	left: 0;
	width: 256px;
	padding-top: var(--spacing-default);
	box-shadow: 2px 0 5px rgba(0, 0, 0, 0.26);
}

@media only screen and (min-width: 1024px) {
	.main-navigation__drawer {
		display: inline-flex;
		left: auto;
		right: auto;
		margin: 0 auto;
	}
}

.main-navigation__label {
	flex: 1;
}

.main-navigation__label .material-icons {
	color: rgba(0, 0, 0, 0.4);
}

.main-navigation__item--sub > .main-navigation__label {
	/* font-weight: bold; */
	display: flex;
	flex-direction: row;
}

.main-navigation__item--sub > .main-navigation__menu {
	padding-left: var(--spacing-default);
}

.main-navigation__link,
.main-navigation__item--sub > .main-navigation__label {
	display: flex;
	flex-direction: row;
	width: 100%;
	padding: var(--spacing-default) calc(var(--spacing-default) * 2);
}

.main-navigation__item .main-navigation__link:not(:hover) {
	color: inherit;
}

.main-navigation__icon path {
	fill: currentColor;
}

.main-navigation--show:before {
	visibility: visible;
	opacity: 0.54;
	transition: none;
}

.main-navigation--show > .main-navigation__drawer {
	transition: transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
	transform: translateX(0);
	visibility: visible;
}

@media (min-width: 768px) {
	.main-navigation__drawer {
		transform: none;
		transition: none;
		visibility: visible;
		height: var(--header-height);
		box-shadow: none;
		overflow: visible;
		/* left: 120px;
        right: 130px; */
		left: auto;
		right: auto;
		margin: 0 auto;
		width: auto;
		padding-top: 0;
	}
	.impersonator .main-navigation__drawer {
		top: var(--impersonator-banner-height);
	}
	.main-navigation:before {
		transition: none;
	}

	.main-navigation__drawer .icon,
	.main-navigation__item--sub > .main-navigation__menu {
		display: none;
	}

	.main-navigation__item--sub.open > .main-navigation__menu {
		display: block;
		position: absolute;
		top: var(--header-height);
		left: 0;
		margin: 0;
		padding: 0;
		height: auto;
		min-width: 250px;
		flex-direction: column;
	}

	.main-navigation__item--sub.open,
	.main-navigation__item--sub.open .main-navigation__link {
		background: var(--highlight-color);
	}

	.main-navigation__item--sub.open .main-navigation__link {
		border-bottom: 1px solid rgba(255, 255, 255, 0.5);
	}

	.main-navigation__item--sub.open li:last-child .main-navigation__link {
		border-bottom: 0;
	}

	.main-navigation__item--sub.open .main-navigation__menu {
		border: 1px solid rgba(0, 0, 0, 0.05);
		border-top: 0;
	}

	.main-navigation__menu {
		flex-direction: row;
		height: var(--header-height);
		margin: 0;
		justify-content: center;
	}

	.main-navigation__item--sub {
		position: relative;
	}

	.main-navigation__label,
	.main-navigation__item--sub > .main-navigation__label {
		display: flex;
		text-align: center;
		justify-content: center;
		align-items: center;
		height: var(--header-height);
	}

	.main-navigation__label {
		flex: none;
	}

	.main-navigation__link,
	.main-navigation__item--sub > .main-navigation__label {
		width: auto;
		padding: 0 10px;
	}
}
