/*
 * Place component specific variables also in this file. The static-styles
 * (for IE11) generator won't pick up variables if it is not :root scoped.
 */

:root {
	--icon-size: 1.714285714rem;
	--icon-size-large: calc(var(--icon-size) * 2);

	--spacing-default: 0.775rem;
	--spacing-half: calc(var(--spacing-default) * 0.5);
	--spacing-double: calc(var(--spacing-default) * 2);

	--default-text-color: #444444;
	--disabled-text-color: #cccccc;
	--disabled-input-background: #f9f9f9;
	--menu-link-color: #ffffff;
	--default-icon-color: rgba(0, 0, 0, 0.54);
	--menu-border-color: #cccccc;
	--menu-background-color: #ccc;
	--error-color: #ff0000;
	--input-border-color: #cccccc;
	--collapsible-color: #f4f4f4;
	--border-gray-color: #d6d6d6;
	--collapsible-arrow-color: #cacaca;
	--collapsible-arrow-background: #cdcdcd;
	--collapsible-header-border-color: #eeeeee;
	--input-border-color-active: var(--default-text-color);
	--usage-measurements-top-color: #fcf1f5;
	--usage-measurements-bottom-color: #fdf8f5;
	--address-box-border: #dddddd;
	--address-box-background: #f9f9f9;
	--tooltip-background-color: #f7f7f7;
	--tooltip-border-color: #d1d1d1;
	--success-green-color: #69c900;
	--table-background-color: #fafafa;
	--table-darker-background-color: #f5f5f5;
	--impersonator-banner-height: 25px;

	--background-fade: 0.54;

	--z-index-low: 0;
	--z-index-medium: 1;
	--z-index-high: 2;

	--header-height: 3.571428571rem;

	--max-width: 1000px;
	--max-dashboard-width: 1200px;

	--border-radius: 7px;
}

/*
 * Slider component variables
 */
:root {
	--slider-total-height: 2rem;
	--slider-total-height-half: calc(var(--slider-total-height) / 2);
	--slider-track-height: 2px;
	--slider-track-height-half: calc(var(--slider-track-height) / 2);
	--slider-extra-width: 1rem;
	--slider-extra-width-half: calc(var(--slider-extra-width) / 2);
}
