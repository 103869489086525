.periodical-payment {
	flex: 1 1 30%;
	margin: var(--spacing-default);
	max-width: 30%;
}
.periodical-payment > .panel {
	min-height: 276px;
}
.periodical-payment-agreement-address {
	font-weight: bold;
}
.periodical-payment-agreement-address > div {
	display: flex;
}
.periodical-payment-agreement-address > div > div {
	width: 100%;
}
.periodical-payment-agreement-address:after {
	margin: var(--spacing-default) 0;
	content: '';
	display: block;
	left: 2px;
	right: 2px;
	border-bottom: 1px solid var(--collapsible-header-border-color);
}
.address-tooltip {
	max-width: 800px;
	max-height: 350px;
	white-space: normal;
	overflow: hidden;
	overflow-y: auto;
	text-overflow: ellipsis;
	padding-left: 24px;
}
.address-tooltip span {
	position: sticky;
	display: none;
	width: 22px;
	height: 22px;
	float: right;
	margin-right: -22px;
}
.address-tooltip.top-arrow span:nth-child(1) {
	top: 4px;
	display: inline-block;
}
.address-tooltip.bottom-arrow span:nth-child(2) {
	top: 305px;
	display: inline-block;
}

.periodical-payment-agreement-name {
	font-size: 18px;
	color: rgba(0, 0, 0, 0.44);
	font-weight: 600;
}
.periodical-payment-agreement-details:after {
	margin: var(--spacing-default) 0;
	content: '';
	display: block;
	left: 2px;
	right: 2px;
	border-bottom: 1px solid var(--collapsible-header-border-color);
}
.periodical-payment-current-label {
	font-weight: bold;
}
.periodical-payment-amount {
	font-size: 2.5em;
	display: block;
}
.periodical-payment-edit-button i {
	float: left;
	font-size: 18px;
	margin-right: 10px;
}

@media only screen and (max-width: 767px) {
	.periodical-payment {
		max-width: 100%;
		width: 100%;
		margin: var(--spacing-default) 0;
		flex: 0 0 auto;
	}
}

@media only screen and (max-width: 420px) {
	.address-tooltip {
		max-width: 300px;
	}
}
