.periodical-payments {
	display: flex;
	justify-content: flex-start;
	flex-wrap: wrap;
	margin: 0 calc(var(--spacing-default) * -1);
}

@media only screen and (max-width: 767px) {
	.periodical-payments {
		flex-direction: column;
		margin: 0;
	}
}
