.contact-info__name {
	margin-bottom: 0;
}

.contact-info .address {
	margin-bottom: 1em;
}

.contact-info .edit-button {
	display: inline-block;
	flex: 0 0 100px;
}
