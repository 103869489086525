.description-list {
	display: flex;
	flex-wrap: wrap;
	margin: 0;
}
.description-list dt {
	opacity: 0.5;
	padding-right: 1em;
	min-width: 40%;
}
.description-list dd,
.description-list dt {
	flex: 1;

	margin: 0;
}
.description-list dd {
	padding-left: 1em;
	min-width: 60%;
}
