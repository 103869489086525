.request-periodical-payment__header {
	display: flex;
	flex-direction: row;
	margin-bottom: var(--spacing-default);
	justify-content: center;
}

.request-periodical-payment__requested {
	display: flex;
	font-size: 20px;
	position: relative;
	border-bottom-style: ridge;
}

.request-periodical-payment__label {
	margin-right: 0.5rem;
}

.request-periodical-payment__label:after {
	content: ':';
}

@media only screen and (min-device-width: 320px) and (max-device-width: 320px) {
	.request-periodical-payment__header {
		align-items: center;
		display: flex;
		text-align: center;
	}

	.request-periodical-payment__requested {
		display: inline-block;
		border-bottom-style: none;
		justify-content: center;
	}
}

@media only screen and (min-device-width: 360px) and (max-device-width: 375px) {
	.request-periodical-payment__header {
		display: flex;
		justify-content: center;
		text-align: center;
		margin-left: 10%;
	}

	.request-periodical-payment__requested {
		display: inline-block;
		border-bottom-style: none;
		justify-content: center;
	}
}
