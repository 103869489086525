@tailwind base;
@tailwind components;
@tailwind utilities;

*,
:before,
:after {
	box-sizing: border-box;
}

body {
	margin: 0;
	padding: 0;
	color: var(--default-text-color);
}

button {
	cursor: pointer;
	font-family: inherit;
}

.no-js .app__main-navigation {
	position: static;
}
