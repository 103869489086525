.invoices-per-year .collapsible {
	border-radius: 0;
	box-shadow: none;
	border: 1px solid var(--collapsible-header-border-color);
}

.invoices-per-year .collapsible .panel {
	padding: 0;
}

.invoices-per-year .collapsible:last-child {
	margin: 0;
}

.invoices-per-year .collapsible .panel .panel__content {
	padding: var(--spacing-default);
}

.invoices-per-year .collapsible__header-button {
	background-color: var(--address-box-background);
}

.invoices-per-year .collapsible .collapsible__header:after {
	content: none;
}

.invoices-per-year .collapsible--expanded .collapsible__header:after {
	content: ' ';
	border-color: var(--collapsible-header-border-color);
}

.invoices-per-year .collapsible .panel {
	padding: 0;
}

.view-invoices__list tbody {
	width: 100%;
	border: 1px solid var(--collapsible-header-border-color);
}

.view-invoices__list tr td,
.view-invoices__list tr th {
	padding: 0 0 0 var(--spacing-half);
	white-space: nowrap;
}

.view-invoices__list tr th {
	font-weight: normal;
	padding-bottom: var(--spacing-half);
}

.view-invoices__list tr.invoice > td:nth-child(1) {
	width: 40%;
}
.view-invoices__list tr.invoice > td:nth-child(2) {
	width: 21%;
	white-space: normal;
}

.view-invoices__list tr.invoice > td:nth-child(3),
.view-invoices__list tr.invoice > td:nth-child(5) {
	width: 12%;
}

.view-invoices__list tr td:last-child,
.view-invoices__list tr th:last-child {
	padding: 0 var(--spacing-default) 0 0;
}

.view-invoices__list thead {
	width: 100%;
	text-align: left;
}

.view-invoices__list tr td:last-child {
	text-align: right;
}

.view-invoices__list__collapsible-icon {
	color: rgba(0, 0, 0, 0.54);
}
.invoices__agreement-details {
	display: none;
	font-weight: bold;
	margin: var(--spacing-default) 0;
}

.invoices__agreement-details span:after {
	content: '|';
	margin: 0 10px;
	display: inline;
}
.invoices__agreement-details span:last-child:after {
	content: '';
	margin: 0;
}

@media screen and (max-width: 767px) {
	.view-invoices__list .download-link {
		margin: var(--spacing-default) 0;
	}

	.view-invoices__list tr th {
		display: none;
	}

	.view-invoices__list tr td {
		width: 100%;
		display: flex;
		align-items: center;
	}

	.view-invoices__list tr td:not(:last-child) {
		border: 0;
	}

	.view-invoices__list tr td:last-child {
		width: 100%;
		justify-content: center;
		text-align: none;
	}

	.invoices__agreement-details {
		display: block;
	}

	.mobile-wide .invoices-per-year .collapsible {
		border: none;
	}

	.mobile-wide .invoices__agreement-details {
		padding: 0 calc(var(--spacing-default) * 1.5);
	}

	.mobile-wide .collapsible__content {
		padding: var(--spacing-default) 0;
	}

	.invoices-per-year .collapsible__content,
	.invoices-per-year .collapsible .panel .panel__content {
		padding: 0;
	}

	.view-invoices__list tr.invoice > td:nth-child(1),
	.view-invoices__list tr.invoice > td:nth-child(2),
	.view-invoices__list tr.invoice > td:nth-child(3),
	.view-invoices__list tr.invoice > td:nth-child(5) {
		width: unset;
	}
}
