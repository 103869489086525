.card {
	border-radius: 0;
	box-shadow: none;
	border: 1px solid var(--collapsible-header-border-color);
}

.card:not(:last-child) {
	margin: 0 0 var(--spacing-double) 0;
}

@media only screen and (max-width: 767px) {
	.card {
		border: 0;
		border-bottom: 1px solid var(--collapsible-header-border-color);
		border-top: 1px solid var(--collapsible-header-border-color);
	}
}
