[data-name='payment-details'] dl dd {
	display: flex;
	justify-content: space-between;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 850px) {
	[data-name='payment-details'] dd {
		min-width: 100%;
	}
}

.agreement-address-wrapper {
	display: flex;
	justify-content: flex-start;
	margin: 0 calc(var(--spacing-default) * -1);
}

.agreement-address-wrapper p {
	margin: 0;
}

.agreement-address-wrapper .address-box .address-box__details {
	justify-content: normal;
}

.view-account .email,
.view-account .phonenumber,
.view-account .accountUsername,
.view-account .accountPassword {
	justify-content: space-between;
}

.view-account .email div,
.view-account .phonenumber div,
.view-account .accountUsername div,
.view-account .accountPassword div {
	width: 1px;
	flex: 1 1 0%;
}

@media only screen and (max-width: 767px) {
	.agreement-address-wrapper {
		flex-direction: column;
		margin: 0;
	}
}
