.weather-box {
	display: flex;
	justify-content: space-evenly;
	height: 75%;
	margin-top: 15px;
	position: relative;
}
.weather-box .status,
.weather-box .temperature,
.weather-box .wind {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.weather-box span {
	flex-grow: 1;
}

.weather-box .icon,
.weather-box .icon svg {
	width: 90px;
	height: 90px;
}
.weather-box .refresh {
	position: absolute;
	top: -70px;
	right: -12px;
	cursor: pointer;
	width: 24px;
	height: 24px;
}
.weather-box .refresh .icon,
.weather-box .refresh .icon svg {
	width: unset;
	height: unset;
}
.no-weather-data {
	display: flex;
	flex-direction: column;
	text-align: center;
	align-items: center;
	color: var(--disabled-text-color);
}

.no-weather-data .icon svg {
	fill: var(--tertiary-color) !important;
}

.rotate {
	-webkit-animation: spin 1s linear infinite;
	-moz-animation: spin 1s linear infinite;
	animation: spin 1s linear infinite;
}
@-moz-keyframes spin {
	100% {
		-moz-transform: rotate(-360deg);
	}
}
@-webkit-keyframes spin {
	100% {
		-webkit-transform: rotate(-360deg);
	}
}
@keyframes spin {
	100% {
		-webkit-transform: rotate(-360deg);
		transform: rotate(-360deg);
	}
}

@media (max-width: 767px) {
	.weather-box .refresh {
		top: -72px;
		right: 35px;
	}
}
