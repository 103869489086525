.phonenumber {
	display: flex;
	align-items: center;
	justify-content: flex-start;
}

.phonenumber:first-child {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.phonenumber p {
	display: flex;
	align-items: center;
	margin-bottom: 0;
	margin-top: 0;
}

.phonenumber svg {
	fill: var(--default-text-color);
}

.phonenumber div {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
