.move-wizard .panel-footer {
	button, .link {
		&::after, &::before {
			content: '';
			position: relative;
		}
	}
	.panel-footer__link--left {
		button {
			padding: 10px 20px 10px 30px;
			&::before {
				left: -15px;
				border: solid var(--email-button-background-color);
				border-width: 0 1px 1px 0;
				display: inline-block;
				padding: 3px;
				transform: rotate(135deg);
  			-webkit-transform: rotate(135deg);
			}
		}
	}
	.panel-footer__link--right {
		.link.link--disabled {
			padding: 10px 30px 10px 20px;
			&::after {
				right: -15px;
				border: solid var(--disabled-text-color);
				border-width: 0 1px 1px 0;
				display: inline-block;
				padding: 3px;
				transform: rotate(-45deg);
				-webkit-transform: rotate(-45deg);
			}
		}
		button {
			padding: 10px 30px 10px 20px;
			&::after {
				right: -15px;
				border: solid #fff;
				border-width: 0 1px 1px 0;
				display: inline-block;
				padding: 3px;
				transform: rotate(-45deg);
				-webkit-transform: rotate(-45deg);
			}
		}
	}
}

.move-wizard h3 {
	font-weight: bold;
	margin-bottom: 20px;
}
.move-wizard .move-wizard__date-input {
	margin-top: 0;
	padding-left: 35px;
	&.fieldset--invalid {
		.input {
			box-shadow: inset 0 0 0 2px var(--color-error);
		}
	}
	.icon {
		position: absolute;
		left: 40px;
		bottom: 4px;
		svg {
			fill: #ccc;
			width: 20px;
		}
	}
	.input {
		border-radius: 3px;
		background: #fff;
		input {
			padding-left: 30px;
			color: var(--default-text-color);
			&::placeholder {
				color: #ccc;
			}
		}
	}
	.input--custom-date {
		width: 170px;
	}
}

.move-wizard__stepper-horizontal {
	display: flex;
	width: 100%;
	margin: 0 auto;
	justify-content: space-between;
}
.move-wizard__stepper-horizontal__hide {
	display: none;
}
.move-wizard__stepper-horizontal .stepper-horizontal_step {
	display: inline-flex;
	position: relative;
	padding: 24px 0;
	flex-direction: column;
	flex: 1;
}
.move-wizard__stepper-horizontal .stepper-horizontal_step:active {
	border-radius: 15% / 75%;
}
.move-wizard__stepper-horizontal .stepper-horizontal_step:first-child:active {
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
}
.move-wizard__stepper-horizontal .stepper-horizontal_step:last-child:active {
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
}

.move-wizard__stepper-horizontal
	.stepper-horizontal_step:first-child
	.stepper-horizontal_step-bar-left,
.move-wizard__stepper-horizontal
	.stepper-horizontal_step:last-child
	.stepper-horizontal_step-bar-right {
	display: none;
}
.move-wizard__stepper-horizontal
	.stepper-horizontal_step
	.stepper-horizontal_step-circle {
	width: 40px;
	height: 40px;
	margin: 0 auto;
	background-color: rgba(var(--primary-colorRGB), 0.3);
	border-radius: 50%;
	text-align: center;
	line-height: 40px;
	font-size: 16px;
	font-weight: 600;
	color: #ffffff;
	&.valid {
		cursor: pointer;
	}
}
.move-wizard__stepper-horizontal.green
	.stepper-horizontal_step.active
	.stepper-horizontal_step-circle {
	background-color: #00ae4d;
}
.move-wizard__stepper-horizontal
	.stepper-horizontal_step.active
	.stepper-horizontal_step-circle {
	background-color: var(--primary-color);
}
.move-wizard__stepper-horizontal
	.stepper-horizontal_step.done
	.stepper-horizontal_step-circle:before {
	font-family: 'FontAwesome';
	font-weight: 100;
	content: '\f00c';
}
.move-wizard__stepper-horizontal
	.stepper-horizontal_step.done
	.stepper-horizontal_step-circle
	*,
.move-wizard__stepper-horizontal
	.stepper-horizontal_step.editable
	.stepper-horizontal_step-circle
	* {
	display: none;
}
.move-wizard__stepper-horizontal
	.stepper-horizontal_step.editable
	.stepper-horizontal_step-circle {
	-moz-transform: scaleX(-1);
	-o-transform: scaleX(-1);
	-webkit-transform: scaleX(-1);
	transform: scaleX(-1);
}
.move-wizard__stepper-horizontal
	.stepper-horizontal_step.editable
	.stepper-horizontal_step-circle:before {
	font-family: 'FontAwesome';
	font-weight: 100;
	content: '\f040';
}
.move-wizard__stepper-horizontal
	.stepper-horizontal_step
	.stepper-horizontal_step-title {
	margin-top: var(--spacing-default);
	font-size: 1rem;
}
.move-wizard__stepper-horizontal
	.stepper-horizontal_step
	.stepper-horizontal_step-title,
.move-wizard__stepper-horizontal
	.stepper-horizontal_step
	.stepper-horizontal_step-optional {
	text-align: center;
	color: rgba(0, 0, 0, 0.26);
}
.move-wizard__stepper-horizontal
	.stepper-horizontal_step.active
	.stepper-horizontal_step-title {
	color: rgba(0, 0, 0, 0.87);
}
.move-wizard__stepper-horizontal
	.stepper-horizontal_step
	.stepper-horizontal_step-optional {
	font-size: 12px;
}
.move-wizard__stepper-horizontal
	.stepper-horizontal_step.active
	.stepper-horizontal_step-optional {
	color: rgba(0, 0, 0, 0.54);
}
.move-wizard__stepper-horizontal
	.stepper-horizontal_step
	.stepper-horizontal_step-bar-left,
.move-wizard__stepper-horizontal
	.stepper-horizontal_step
	.stepper-horizontal_step-bar-right {
	position: absolute;
	top: 44px;
	height: 1px;
	border-top: 1px solid #dddddd;
}
.move-wizard__stepper-horizontal
	.stepper-horizontal_step
	.stepper-horizontal_step-bar-right {
	right: 0;
	left: 50%;
	margin-left: 25px;
}
.move-wizard__stepper-horizontal
	.stepper-horizontal_step
	.stepper-horizontal_step-bar-left {
	left: 0;
	right: 50%;
	margin-right: 25px;
}

.move-wizard__section {
	margin-bottom: 3rem;
	margin-top: 2rem;
}
.move-wizard__section:last-child {
	margin-bottom: 0;
}

.move-wizard__section-title {
	font-weight: 600;
}

.move-wizard__section-title svg {
	fill: var(--primary-color);
	width: 1.5em;
	height: 1.5em;
	margin-left: 0.25em;
}

.move-wizard__section-title a {
	color: var(--primary-color);
}
.move-wizard__section-title a .icon {
	color: var(--primary-color);
}
.move-wizard__fieldset {
	margin-top: 0.5rem;
}

.ios .move-wizard__date-input input {
	min-height: 2.149414062em;
}
