.app__main-content .page-title {
	border-bottom: 1px solid transparent;
	padding: var(--spacing-double) var(--spacing-default) var(--spacing-default) 0;
	margin-left: 0;
	position: relative;
	font-size: 1.6rem;
}
/* .page-title:after {
	content: '';
	display: block;
	width: 100%;
	height: 1px;
	position: absolute;
	left: 0;
	bottom: 0;
	background-color: black;
	opacity: 0.15;
} */
