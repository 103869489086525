.privacy-info__name {
	margin-bottom: 0;
}

.privacy-info .address {
	margin-bottom: 1em;
}

.privacy-info .edit-button {
	display: inline-block;
	flex: 0 0 100px;
}

.privacy-tooltip-width {
	max-width: 400px;
}

.helper-text-size {
	font-size: 11px;
}

.privacy-section-title > h4 {
	flex: 1;
	display: flex;
	align-content: center;
	align-items: center;
	font-weight: bold;
}

.m-r-12 {
	margin-right: 12px;
}

.m-l-12 {
	margin-left: 20px;
}
