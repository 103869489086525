.cognito-forgot-password {
	width: 400px;
	margin: 100px auto;
	padding-bottom: 20px;
}

.cognito-forgot-password .logo,
.cognito-forgot-password .title {
	text-align: center;
}
.cognito-forgot-password .logo {
	display: flex;
	justify-content: center;
	margin-bottom: 80px;
	cursor: pointer;
}

.cognito-forgot-password .logo img {
	max-width: 300px;
	max-height: 300px;
}

.cognito-forgot-password h1 {
	font-weight: 300;
}

.cognito-forgot-password input {
	width: 100%;
	padding: 10px;
	height: 40px;
	border-radius: 5px;
	font-size: 14px;
	background-color: #e0e0e0;
	border: 1px solid #e0e0e0;
	transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
	box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}

.cognito-forgot-password form button {
	width: 100%;
	min-height: 40px;
	background-color: var(--primary-color);
	color: #ffffff;
}
.cognito-forgot-password input:focus {
	border: 1px solid var(--primary-color);
	box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
		0 0 8px rgba(102, 175, 233, 0.6);
	outline: 0;
}
.cognito-forgot-password .resend-code,
.cognito-forgot-password .back-to-signin {
	display: block;
	cursor: pointer;
	font-size: small;
	margin: 10px 0 0 10px;
	color: var(--secondary-color);
}

.cognito-forgot-password footer {
	position: fixed;
	bottom: 0;
	left: 0;
	width: 100%;
	padding: 30px;
	text-align: center;
	color: var(--secondary-color);
	border-top: 1px solid rgba(0, 0, 0, 0.1);
	background-color: rgba(255, 255, 255, 1);
}
.cognito-forgot-password .label-email {
	padding-right: 10px;
}
.cognito-forgot-password .label-email,
.cognito-forgot-password .label-phone {
	color: var(--primary-color);
}
.cognito-forgot-password .lost-username {
	padding: 20px 0;
}
.cognito-forgot-password .spinner-component {
	background-color: rgba(0, 0, 0, 0.2);
	z-index: 1;
}

.cognito-forgot-password button[disabled] {
	color: var(--disabled-text-color);
	cursor: not-allowed;
}

.passwords-not-match {
	color: red;
}

.cognito-forgot-password .lang-switch-navigation {
	position: fixed;
	top: 0;
	right: 10px;
}

@media only screen and (max-width: 767px) {
	.cognito-forgot-password {
		width: 70%;
		margin: 70px auto;
		word-break: break-word;
		padding-bottom: 30px;
	}

	.cognito-forgot-password .logo {
		margin-bottom: 40px;
	}

	.cognito-forgot-password .logo img {
		width: 80%;
	}

	.cognito-forgot-password footer {
		padding: 15px;
	}
}
