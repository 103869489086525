.button {
	font-size: 1rem;
	border: none;
	border-radius: 2px;
	padding: 0.5rem 1rem;
	box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
		0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
	text-transform: uppercase;
}
.button:hover {
	cursor: pointer;
}

.button--transparent {
	box-shadow: none;
	background-color: transparent;
}

.button.primary-bg-color {
	color: var(--primary-color-text-color);
}
