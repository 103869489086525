.header {
	position: fixed;
	top: 0;
	height: var(--header-height);
	width: 100%;
	background-color: #ffffff;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding-left: var(--spacing-half);
	z-index: var(--z-index-high);
	box-shadow: 0 0px 5px rgba(0, 0, 0, 0.16);
}
.header.impersonator {
	top: var(--impersonator-banner-height);
}

.right-menu {
	display: flex;
}

.header--non-sticky {
	position: static;
}

.header .link,
.header button {
	height: 100%;
}

.header .link > a {
	display: flex;
	height: 100%;
	align-items: center;
	justify-content: center;
}

.header__logo {
	max-height: 55%;
	max-width: 110px;
}

.header__button.button {
	padding: 0;
}

.header__logout {
	color: rgba(0, 0, 0, 0.6);
	padding-right: 5px;
}

.header__button--exit {
	display: inline-flex;
	align-items: center;
}

.header__button--exit .primary-icon svg {
	fill: rgba(0, 0, 0, 0.6);
}

.header__button--exit:focus .primary-icon svg,
.header__button--exit:active .primary-icon svg,
.header__button--exit:hover .primary-icon svg {
	fill: var(--primary-color);
}

.header__button--exit:focus span,
.header__button--exit:active span,
.header__button--exit:hover span {
	color: var(--primary-color);
}

@media (min-width: 768px) {
	.header__button--menu {
		display: none;
	}

	.header .link > a {
		justify-content: flex-start;
	}
}
