.ean-code-number {
	position: relative;
	display: flex;
	align-content: center;
	color: var(--secondary-color);
	word-break: break-all;
}

.ean-code-number .icon {
	position: absolute;
	left: -5px;
	top: 11px;
	fill: var(--secondary-color);
}
.measurements-row {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.measurements-row > * {
	flex: 1 1 auto;
}

.no-meter-data-message {
	text-align: center;
}

.measurements-row .measurements-row-top {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.measurements-row .measurements-row-top > * {
	flex: 1 1 auto;
}

.measurements-row .measurements-row-top {
	background-color: var(--usage-measurements-top-color);
}
.measurements-row .measurements-row-bottom {
	background-color: var(--usage-measurements-bottom-color);
}

.measurements-row .measurements-row-top,
.measurements-row .measurements-row-bottom {
	padding: var(--spacing-default);
}

.measurements-row .measurements-row-title {
	display: flex;
	justify-content: center;
	color: var(--secondary-color);
}

.measurements-row .measurements-row-body {
	display: flex;
	justify-content: space-between;
}

.measurements-row .measurements-row-body .measurements-column {
	display: flex;
	flex-direction: column;
	align-content: center;
	justify-content: center;
	flex: 1 1 auto;
}

.measurements-row .measurements-row-body .measurements-column > * {
	display: flex;
	justify-content: center;
	color: var(--secondary-color);
}

.usage__table tbody {
	border: 1px solid var(--collapsible-header-border-color);
}

.usage__table th {
	font-weight: normal;
	text-align: left;
}

.usage__table tr td,
.usage__table tr th {
	padding: 0 0 0 var(--spacing-default);
	color: black;
}

.usage__table tr th {
	font-weight: normal;
	padding-bottom: var(--spacing-half);
}

.usage__table tr td:last-child,
.usage__table tr th:last-child {
	padding: 0 var(--spacing-default) 0 0;
}

.usage-history-panel .panel-footer {
	justify-content: center;
}

.view-usage__usage-production-switch {
	display: inline-flex;
	align-items: center;
}

.view-usage__usage-production-switch {
	padding: 0 0 var(--spacing-double) 0;
}

.view-usage__usage-production-switch > *:last-child {
	margin-left: var(--spacing-default);
}

.usage-history {
	display: flex;
}

.usage-history {
	display: flex;
}

.overlay__content .usage-history .history-table-wrapper {
	position: relative;
}

.usage-history > * {
	flex: 1;
	padding: 0 var(--spacing-half);
}

.usage-history > *:first-child {
	padding-right: var(--spacing-double);
}

.usage-history > *:last-child {
	padding: 0 var(--spacing-half) 0 var(--spacing-double);
	margin: 0 0 0 var(--spacing-half);
	border-left: 1px solid var(--collapsible-header-border-color);
}

.history-table-wrapper thead tr:first-child th {
	font-size: 1.2rem;
}

.history-table-wrapper thead tr:last-child th {
	text-align: left;
	padding: 0 0 0 var(--spacing-double);
}

.history-table-wrapper thead tr th {
	font-weight: bold;
}

.history-table-wrapper thead tr th:nth-child(2),
.history-table-wrapper thead tr th:last-child,
.history-table-wrapper tbody tr td:nth-child(2),
.history-table-wrapper tbody tr td:last-child {
	background-color: var(--table-background-color);
}

.history-table-wrapper tbody tr td:nth-child(2),
.history-table-wrapper tbody tr td:last-child {
	padding: 0 0 0 var(--spacing-double);
}

.usage-history-panel .panel-footer {
	border-top: 1px solid var(--collapsible-header-border-color);
}

.history-table-wrapper__table tbody {
	border: 1px solid var(--collapsible-header-border-color);
}

.history-table-wrapper__table tr {
	height: 42px;
}

.history-table-wrapper__table tbody tr:not(:last-child) td {
	border-bottom: 1px solid var(--collapsible-header-border-color);
}

.history-table-wrapper__table tr td,
.history-table-wrapper__table tr th {
	padding: 0 0 0 var(--spacing-default);
	color: black;
}

.usage-history-panel h3 {
	margin: 0 0 var(--spacing-default) 0;
	font-weight: bold;
}

.table-meter-details tbody tr td {
	padding-bottom: var(--spacing-half);
	word-break: break-all;
}

.history-table-wrapper__table tbody tr td {
	word-break: break-all;
}

@media only screen and (max-width: 767px) {
	.view-usage .collapsible .panel {
		padding: 0;
	}

	.view-usage .collapsible .panel .panel__content {
		padding: 0;
	}

	.view-usage .usage__table {
		margin-top: var(--spacing-double);
	}

	.view-usage .usage__table tbody {
		border: 0;
		border-top: 1px solid var(--collapsible-header-border-color);
	}

	.view-usage__usage-production-switch {
		padding: var(--spacing-double) var(--spacing-default);
	}

	.usage-history {
		flex-direction: column;
	}

	.usage-history > *:first-child {
		order: 2;
		padding: var(--spacing-half) 0;
	}

	.usage-history > *:last-child {
		order: 1;
		margin: 0;
		padding: var(--spacing-half) 0;
		padding: 0 var(--spacing-half) var(--spacing-double) 0;
		margin: 0 0 var(--spacing-default) 0;
		border-left: none;
		border-bottom: 1px solid var(--collapsible-header-border-color);
	}

	.usage-history > * {
		padding: var(--spacing-half) 0;
		margin: 0;
	}

	/* .history-table-wrapper tbody tr td:nth-last-child(2),
	.history-table-wrapper tbody tr td:last-child {
		display: none;
	}

	.history-table-wrapper thead tr:last-child th:last-child,
	.history-table-wrapper thead tr:last-child th:nth-last-child(2) {
		display: none;
	}
	.history-table-wrapper thead tr:first-child th:last-child {
		display: none;
    } */

	.usage-history-panel .panel__content {
		padding: var(--spacing-half);
	}
	.history-table-wrapper__table tr td {
		font-size: 0.9rem;
	}
}
