.meter-measurements-electricity {
	width: 100%;
}

.meter-measurements-electricity__table-head {
	display: none;
}

.meter-measurements-electricity__table-body,
.meter-measurements-electricity__row,
.meter-measurements-electricity__cell {
	display: block;
}

.meter-measurements-electricity__cell {
	width: 50%;
	float: left;
	height: 6rem;
	padding-bottom: 0.5rem;
	display: flex;
	justify-content: center;
	align-items: flex-end;
	position: relative;
}

.meter-measurements-electricity__row {
	margin-bottom: var(--spacing-double);
	border-bottom: 1px solid var(--menu-border-color);
	padding-bottom: var(--spacing-double);
}
.meter-measurements-electricity__row:last-child {
	border-bottom: none;
	margin-bottom: 0;
	padding-bottom: 0;
}
.meter-measurements-electricity__row:after {
	content: '';
	display: table;
	clear: both;
}

.meter-measurements-electricity__date {
	width: 100%;
	height: calc(1.5rem + var(--spacing-half));
	text-align: center;
	padding-bottom: var(--spacing-half);
	font-weight: 700;
	cursor: pointer;
}

.meter-measurements-electricity__usage {
	z-index: 0;
}
.meter-measurements-electricity__usage:before {
	content: attr(data-usage);
	display: block;
	width: 200%;
	position: absolute;
	top: 0.5rem;
	left: 0;
	text-align: center;
	background-color: var(--highlight-color);
	height: calc(100% - 0.5rem);
	z-index: -1;
}

.meter-measurements-electricity__production:before {
	content: attr(data-production);
	display: block;
	width: 200%;
	position: absolute;
	top: 0.5rem;
	left: 0;
	text-align: center;
}

.meter-measurements-electricity__standard:after {
	content: attr(data-standard);
	display: block;
	width: 100%;
	position: absolute;
	top: 2em;
	left: 0;
	text-align: center;
}

.meter-measurements-electricity__low:after {
	content: attr(data-low);
	display: block;
	width: 100%;
	position: absolute;
	top: 2em;
	left: 0;
	text-align: center;
}

.meter-measurements-electricity__row--hidden
	.meter-measurements-electricity__standard,
.meter-measurements-electricity__row--hidden
	.meter-measurements-electricity__low {
	display: none;
}
