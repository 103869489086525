.ask-a-question {
	position: relative;
}
.ask-a-question__form {
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
}
.ask-a-question__form .input--textarea span.icon {
	position: absolute;
	top: 5px;
	right: 0;
}

.contact-form__ask-form .ask-a-question__form .input--textarea textarea {
	padding-right: 40px;
}

.ask-a-question--submitted .ask-a-question__form {
	visibility: hidden;
}
.ask-a-question__submit-feedback {
	position: absolute;
	top: 50%;
	width: 100%;
	left: 0;
	text-align: center;
	transform: translateY(-50%);
}
.ask-a-question__submit {
	align-self: flex-end;
	margin-right: 45px;
}
