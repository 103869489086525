.view-home {
	padding-top: var(--spacing-half);
}
.view-home__periodical-payment-amount {
	font-size: 2.5em;
	display: block;
	text-align: right;
}
.view-home__periodical-payment-amount-change-text {
	text-align: right;
	display: block;
}
