.footer {
	position: relative;
	text-align: center;
	padding: var(--spacing-double) var(--spacing-default) var(--spacing-default) 0;
}

.footer > * {
	position: relative;
}

.footer:before {
	content: '';
	background-color: white;
	position: absolute;
	width: 100%;
	height: 100%;
	display: block;
	top: 0;
	opacity: var(--background-fade);
}
