.move-wizard-step-4 {
 	.icon {
		margin: 1rem auto;
		svg {
			fill: var(--primary-color)
		}
	}
	p {
		text-align: center;
	}
	.panel-header-inner {
		padding: var(--spacing-default) 0;
	}
}
