.address-box-wrapper {
	min-height: 192px;
	flex: 1 1 33.33%;
	max-width: calc(33.33%);
	padding: var(--spacing-default);
	display: flex;
}

.address-box {
	min-height: 192px;
	background-color: var(--address-box-background);
	border: 1px solid var(--address-box-border);
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	padding: 5px 5px 15px 5px;
	flex: 1;
}

.address-box__daughter-companies {
	min-height: 20px;
	margin-bottom: 5px;
}

.address-box__daughter-companies > div {
	text-align: center;
	font-weight: bold;
	font-size: 12px;
}

.address-box-content {
	margin-top: 10px;
}

@media only screen and (max-width: 767px) {
	.address-box-wrapper {
		max-width: 100%;
		width: 100%;
		padding: var(--spacing-default) 0;
	}
}

.address-box.address-box-selected {
	background-color: rgba(var(--primary-colorRGB), 0.05);
	border: 2px solid rgba(var(--primary-colorRGB), 0.6);
}

.address-box i {
	color: var(--address-box-border);
	color: rgba(0, 0, 0, 0.54);
}

.address-box.address-box-selected *,
.address-box.address-box-selected i,
.address-box.address-box-selected .icon {
	color: var(--primary-color);
	fill: var(--primary-color);
}

.address-box .address-box__details {
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	flex: 1 1 auto;
	padding: 26px 0 0 0;
}

.address-box .address-box__details * {
	text-align: center;
	font-size: 0.9rem;
}

.address-box .address-box__details .tooltip-link {
	color: var(--primary-color);
	cursor: pointer;
}

.address-box .address-box__details .tooltip-link:hover {
	text-decoration: underline;
}

.address-box .address-box__details .extra-tooltip:hover {
	visibility: visible !important;
	opacity: 1 !important;
}

.__react_component_tooltip {
	pointer-events: auto !important;
}

.address-box .extra-tooltip {
	background-color: var(--tooltip-background-color);
	border-radius: var(--border-radius);
	border: 1px solid var(--tooltip-border-color);
	box-shadow: 0 2px 2px 0px rgba(0, 0, 0, 0.25);
	opacity: 1;
	z-index: 1000;
	padding: var(--spacing-half) var(--spacing-default);
}

.address-box .extra-tooltip.place-bottom:before {
	width: 10px;
	height: 10px;
	border: 0;
	transform: rotate(45deg);
	border-top: 1px solid var(--tooltip-border-color);
	border-left: 1px solid var(--tooltip-border-color);
	background-color: var(--tooltip-background-color);
	margin-left: -5px;
	top: -6px;
}

.address-box .extra-tooltip.place-bottom:after {
	content: none;
}

.address-box .extra-tooltip * {
	color: black;
}

.address-box .extra-tooltip a {
	color: var(--primary-color);
	cursor: pointer;
}

.address-box .extra-tooltip a:hover {
	text-decoration: underline;
}

.address-box__details__city-name {
	white-space: nowrap;
	word-break: keep-all;
}
