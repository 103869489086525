@font-face {
	font-family: 'icomoon';
	src: url('fonts/icomoon.eot?7bzozj');
	src: url('fonts/icomoon.eot?7bzozj#iefix') format('embedded-opentype'),
		url('fonts/icomoon.ttf?7bzozj') format('truetype'),
		url('fonts/icomoon.woff?7bzozj') format('woff'),
		url('fonts/icomoon.svg?7bzozj#icomoon') format('svg');
	font-weight: normal;
	font-style: normal;
}

[class^='icon-'],
[class*=' icon-'] {
	/* use !important to prevent issues with browser extensions that change fonts */
	font-family: 'icomoon' !important;
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.icon-energy-2-3:before {
	content: '\e909';
}
.icon-energy-1-3:before {
	content: '\e90a';
}
.icon-turbine-base:before {
	content: '\e905';
}
.icon-blade-border:before {
	content: '\e907';
}
.icon-turbine-blades:before {
	content: '\e906';
}
.icon-energy-time:before {
	content: '\e900';
}
.icon-participation:before {
	content: '\e901';
}
.icon-total-energy:before {
	content: '\e902';
}
.icon-clock-empty:before {
	content: '\e903';
}
.icon-energy-angle:before {
	content: '\e904';
}
.icon-home3:before {
	content: '\e908';
}
.icon-bullhorn:before {
	content: '\e91a';
}
.icon-file-text:before {
	content: '\e922';
}
.icon-coin-dollar:before {
	content: '\e93b';
}
.icon-credit-card:before {
	content: '\e93f';
}
.icon-envelop:before {
	content: '\e945';
}
.icon-user:before {
	content: '\e971';
}
.icon-meter:before {
	content: '\e9a6';
}
.icon-truck:before {
	content: '\e9b0';
}
.icon-sphere:before {
	content: '\e9c9';
}
.icon-flickr4:before {
	content: '\eaa6';
}
