.card-reveal {
	width: 100%;
	display: flex;
	@media only screen and (max-width: 767px) {
		flex-direction: column;
	}
	.card-0 {
		z-index: 1;
		width: 50%;
		padding: 20px 30px;
		background: var(--table-even-row-background-color);
		color: #000;
		@media only screen and (max-width: 767px) {
			width: 100%;
			padding: 10px 20px;
		}
	}
	.card-1 {
		width: 50%;
		padding: 20px 30px;
		background: var(--table-odd-row-background-color);
		color: #000;
		position: relative;
		@media only screen and (max-width: 767px) {
			width: 100%;
		}
		&::before {
			content: '';
			display: block;
			position: absolute;
			top: 50%;
			left: -1px;
			width: 0;
			margin-top: -15px;
			height: 0;
			border-top: 15px solid transparent;
			border-bottom: 15px solid transparent;
			border-left: 15px solid var(--table-even-row-background-color);
			@media only screen and (max-width: 767px) {
				border-bottom: unset;
				border-left: 15px solid transparent;
				border-right: 15px solid transparent;
				border-top: 15px solid var(--table-even-row-background-color);
				top: -1px;
				left: 50%;
				margin-left: -15px;
				margin-top: 0;
			}
		}
		svg {
			fill: var(--primary-color);
		}
	}
	.reveal-node-enter {
			opacity: 0;
			transform: scale(0.8) translateX(-100px);
			@media only screen and (max-width: 767px) {
				transform: scale(0.8) translateY(-100px);
			}
		}
	.reveal-node-enter-active {
		opacity: 1;
		transform: scale(1) translateX(0);
		transition: opacity 300ms, transform 200ms ease-in;
		@media only screen and (max-width: 767px) {
			transform: scale(1) translateY(0);
		}
	}
	.reveal-node-exit {
		opacity: 1;
	}
	.reveal-node-exit-active {
		opacity: 0;
		transform: scale(0.9) translateX(-100px);
		transition: opacity 300ms, transform 200ms ease-out;
		@media only screen and (max-width: 767px) {
			transform: scale(0.9) translateY(-100px);
			transition: opacity 300ms, transform 300ms ease-out;
		}
	}

}
