.edit-button__icon {
	margin-left: var(--spacing-default);
	display: inline-flex;
}

.edit-button {
	display: inline-flex;
}

.edit-button .link,
.edit-button .disabled-link {
	display: inline-flex;
}

.edit-button a,
.edit-button button {
	padding: 0;
	font: inherit;
	color: inherit;
	display: inline-flex;
}

.edit-button svg {
	fill: currentColor;
}

.edit-button.edit-button--disabled {
	color: var(--disabled-text-color);
}

.edit-button .edit-button__icon {
	display: inline;
}
