.reactivate-account button {
	position: relative;
	width: 100%;
	min-height: 40px;
	background-color: var(--primary-color);
	color: #ffffff;
}
.reactivate-account button .spinner-component {
	left: unset;
	bottom: unset;
	top: 10px;
	right: 10px;
}
.reactivate-account button[disabled] {
	color: var(--disabled-text-color);
	background-color: var(--disabled-input-background);
	cursor: not-allowed;
}
.reactivate-account p {
	color: var(--default-text-color);
}

.reactivate-account .email-sent {
	font-size: 14px;
	color: var(--default-text-color);
}

.reactivate-account .error-message {
	display: block;
	color: var(--error-color);
	padding-bottom: 10px;
}
