.usage-row {
	height: 42px;
}

.usage-row:not(:last-child) td {
	border-bottom: 1px solid var(--collapsible-header-border-color);
}

.usage-row a {
	color: var(--primary-color);
	text-transform: uppercase;
	white-space: nowrap;
}

.usage-row td {
	width: 30%;
}

.usage-row td:first-child {
	width: 35px;
	text-align: center;
}

.usage__table .usage-row td:last-child {
	width: 18%;
	text-align: right;
	padding-left: var(--spacing-default);
}

.usage__table .usage-row td:last-child i {
	display: none;
}

.usage__table .usage-row .usage__ean-truncated {
	display: none;
	color: black;
}

.usage__table .error-text {
	padding: 10px 0;
}

.usage__table .usage-row .usage__ean-truncated:hover,
.usage__table .usage-row .usage__ean-truncated:focus,
.usage__table .usage-row .usage__ean-truncated:active {
	text-decoration: none;
}

.collapsible .spinner-component {
	min-height: 0;
	min-height: 42px;
}

@media only screen and (max-width: 767px) {
	.usage__table .usage-row td:nth-child(2) > div > div {
		display: inline-flex;
	}

	.usage__table .usage-row td:nth-child(2) > div > div:before {
		content: '...';
	}

	.usage__table .usage-row td:nth-child(2) > div > div > span {
		width: 24px;
		display: inline-flex;
		overflow: hidden;
		position: relative;
	}

	.usage__table .usage-row td:nth-child(2) > div > div > span > span {
		display: inline-flex;
		position: absolute;
		right: 0;
	}

	.usage__table .usage-row .usage__ean-truncated {
		display: inline;
	}

	.usage__table .usage-row td:nth-child(2) > span:first-child {
		display: none;
	}

	.usage__table .usage-row td:nth-child(5) {
		width: 1%;
	}

	/* .usage__table .usage-row td:nth-child(5) > * {
		display: none;
	} */

	.usage__table .usage-row td:last-child i {
		display: inline;
	}
	.usage__table .usage-row td:last-child .usage-row__history {
		display: none;
	}
}
