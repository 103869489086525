.input {
	border: 1px solid var(--input-border-color);
	font-size: 1rem;
	position: relative;
	display: flex;
}

.input input {
	line-height: 1.75em;
}
.input input,
.input textarea {
	border: none;
	background-color: transparent;
	font-size: 1rem;
	flex: 1;
	padding: 0;
	padding-left: 10px;
	padding-top: 0.25em;
	padding-bottom: 0.15em;
	font-family: inherit;
}

.input__date--hide {
	opacity: 0;
}

.input__date-cover {
	position: absolute;
	left: 0;
	top: 0;
	height: 100%;
	width: 100%;
	display: flex;
	align-items: center;
	pointer-events: none;
}

.input__date-clear {
	z-index: var(--z-index-medium);
}

.ios .input input,
.ios .input textarea {
	font-size: 16px;
}

.input input::-webkit-inner-spin-button,
.input input::-webkit-search-cancel-button,
.input input::-webkit-search-results-button {
	display: none;
	-webkit-appearance: none;
}

.input input::-webkit-outer-spin-button,
.input input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

.input input {
	-moz-appearance: textfield;
}

input[type='date']::-webkit-clear-button {
	-webkit-appearance: none;
	display: none;
}

.input--custom-date {
	display: flex;
}

.input--custom-date > div {
	display: flex;
	flex: 1 1 auto;
}

.react-datepicker-wrapper,
.react-datepicker__input-container {
	display: flex;
	flex: 1 1 auto;
}
.input--invalid:after {
	background-color: var(--error-color);
	opacity: 1;
}
input[type='text']::-ms-clear {
	display: none;
}
input[type='date']::-moz-clear {
	display: none;
}
