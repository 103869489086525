.panel-footer {
	min-height: 50px;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	padding: var(--spacing-double) var(--spacing-double);
	position: relative;
	border-top: 1px solid var(--collapsible-header-border-color);
}
.panel-footer--is-empty {
	min-height: 0;
	padding: 0;
	border: none;
}
.panel-footer__button,
.panel-footer__link {
	text-transform: uppercase;
	font-size: small;
	border: none;
	background-color: transparent;
	margin: 0;
}

.panel-footer__button {
	padding: 10px 20px;
}

.panel-footer__link--left {
	.panel-footer__button {
		color: var(--email-button-font-color);
		background-color: #fff;
		border: 1px solid var(--email-button-background-color);
	}
}

.panel-footer__link--right {
	.panel-footer__button {
		color: var(--email-button-font-color);
		background-color: var(--email-button-background-color);
	}
}

.panel-footer__link .link {
	padding: 10px 20px;
	background-color: var(--address-box-background);
}

.panel-footer__button--right,
.panel-footer__link--right {
	margin-left: auto;
}
.panel-footer__link .disabled {
	pointer-events: none;
	color: var(--disabled-text-color);
}
