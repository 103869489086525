.panel-header {
	min-height: 48px;
	border-bottom: 1px solid transparent;
	padding: 0;
	position: relative;
}
.panel-header-inner h3 {
	font-weight: bold;
}
.panel-header-inner {
	width: 100%;
	display: flex;
	justify-content: space-between;
	padding: var(--spacing-default) var(--spacing-double);
}
.panel-header-inner button {
	border: 0;
	background: transparent;
}

.panel-header:after {
	content: '';
	display: block;
	left: 2px;
	right: 2px;
	border-bottom: 1px solid var(--collapsible-header-border-color);
	position: absolute;
}

.panel-header.top-border:after {
	display: none;
}
.panel-header.top-border:before {
	content: '';
	display: block;
	left: 2px;
	right: 2px;
	border-top: 1px solid var(--collapsible-header-border-color);
	position: absolute;
}

.panel-header--is-empty {
	min-height: 0;
	padding: 0;
	border: none;
	display: none;
}
.panel-header--is-empty:after {
	content: initial;
}
