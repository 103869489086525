.icon {
	display: block;
}
.icon--large {
	width: var(--icon-size-large);
	height: var(--icon-size-large);
}
.icon--small {
	width: var(--icon-size);
	height: var(--icon-size);
}
.icon--inline {
	display: inline;
	position: relative;
	padding-left: 1.25em;
}
.icon--inline svg {
	position: absolute;
	top: 50%;
	left: 0;
	transform: translateY(-50%);
	width: 1em;
	height: 1em;
}
