.email-confirmation {
	width: 400px;
	margin: 100px auto;
	padding-bottom: 20px;
}

.email-confirmation .logo {
	text-align: center;
}
.email-confirmation .logo {
	margin-bottom: 80px;
}

.email-confirmation .logo img {
	max-width: 300px;
	width: 100%;
}

.email-confirmation h1 {
	font-weight: 300;
}

.email-confirmation .message {
	text-align: center;
}
.email-confirmation .error-message {
	text-align: center;
	color: red;
}
.email-confirmation footer {
	display: flex;
	flex-direction: column;
	position: fixed;
	bottom: 0;
	left: 0;
	width: 100%;
	padding: 30px;
	text-align: center;
	color: var(--secondary-color);
	border-top: 1px solid rgba(0, 0, 0, 0.1);
	background-color: rgba(255, 255, 255, 1);
}

.email-confirmation .lang-switch-navigation {
	position: fixed;
	top: 0;
	right: 10px;
}

@media only screen and (max-width: 767px) {
	.email-confirmation {
		width: 70%;
		margin: 70px auto;
		padding-bottom: 30px;
	}

	.email-confirmation .logo {
		margin-bottom: 40px;
	}

	.email-confirmation .logo img {
		width: 80%;
	}

	.email-confirmation footer {
		padding: 15px;
	}
}
