.account-navigation {
}

.account-navigation {
	display: inline-block;
	justify-content: center;
}

.account-navigation:before {
	content: '';
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100%;
	background: #000;
	visibility: hidden;
	opacity: 0;
	transition: opacity 0.3s 0.2s, visibility 0s 0.5s;
	z-index: var(--z-index-medium);
}

.account-navigation__menu {
	display: flex;
	flex-direction: column;
	list-style-type: none;
	margin-top: 0;
	padding-left: 0;
}

.account-navigation__label {
	width: 100%;
	display: flex;
	justify-content: flex-end;
	flex: 1;
	align-items: center;
}

.account-navigation__item--sub > .account-navigation__label {
	/* font-weight: bold; */
}

.account-navigation__item--sub > .account-navigation__menu {
	padding-left: var(--spacing-default);
}

.account-navigation__link,
.account-navigation__item--sub > .account-navigation__label {
	width: 100%;
	display: flex;
	flex-direction: row;
	padding: var(--spacing-default) calc(var(--spacing-default) * 2);
	text-transform: capitalize;
	white-space: nowrap;
}

.account-navigation__link {
	width: 100%;
	display: flex;
	justify-content: flex-end;
	align-content: center;
}

.account-navigation__link > .account-navigation__label {
	flex: 1 1;
	display: flex;
	justify-content: flex-end;
}

.account-navigation__link > .account-navigation__label > .material-icons {
	margin-left: var(--spacing-half);
}

.account-navigation__link:hover > .account-navigation__label > .material-icons {
	color: var(--secondary-text-color);
}

.account-navigation__label .material-icons {
	color: #444444;
	font-size: 28px;
}

.account-navigation__item--sub
	> .account-navigation__label
	> .account-navigation__label__text {
	padding: 0 0 0 var(--spacing-half);
}

.account-navigation__item .account-navigation__link:not(:hover) {
	color: inherit;
}

.account-navigation__icon path {
	fill: currentColor;
}

.account-navigation:before {
	transition: none;
}

.account-navigation__item--sub > .account-navigation__menu {
	display: none;
}

.account-navigation__item--sub.open > .account-navigation__menu {
	display: block;
	position: absolute;
	top: var(--header-height);
	right: 0;
	margin: 0;
	padding: 0;
	height: auto;
	min-width: 165px;
	width: 100%;
	flex-direction: column;
	box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1);
}

.account-navigation__item--sub.open,
.account-navigation__item--sub.open .account-navigation__link {
	background: white;
}

.account-navigation__item--sub.open .account-navigation__link {
	border-bottom: 1px solid rgba(255, 255, 255, 0.5);
}

.account-navigation__item--sub.open li:last-child .account-navigation__link {
	border-bottom: 0;
}

.account-navigation__item--sub.open .account-navigation__menu {
	border: 1px solid rgba(0, 0, 0, 0.05);
	border-top: 0;
}

.account-navigation__menu {
	flex-direction: row;
	height: var(--header-height);
	margin: 0;
	justify-content: center;
}

.account-navigation__item--sub {
	position: relative;
}

.account-navigation__label,
.account-navigation__item--sub > .account-navigation__label {
	display: flex;
	text-align: center;
	justify-content: center;
	height: var(--header-height);
}

.account-navigation__item--sub ul .account-navigation__label {
	height: calc(var(--header-height) / 1.3);
}

.account-navigation__label {
	flex: none;
}

.account-navigation__link,
.account-navigation__item--sub > .account-navigation__label {
	padding: 0 10px;
}

@media only screen and (max-width: 1100px) {
	.account-navigation__item--sub > span .account-navigation__label__text {
		display: none;
	}
}
