.dashboard-tiles .panel__content {
	height: 100%;
}

.customer-service {
	position: relative;
	height: inherit;
	display: flex;
	flex-direction: column;
}
.customer-service .phonenumber div {
	display: flex;
	align-items: center;
}

.customer-service a {
	text-decoration: none;
}

.customer-service button {
	display: flex;
	align-items: center;
	margin-left: auto;
	margin-top: auto;
	text-transform: capitalize;
	color: var(--primary-color);
}

.customer-service span.link {
	display: flex;
	align-items: center;
	margin-left: auto;
	bottom: 0;
	position: absolute;
	right: 0;
	color: var(--primary-color);
	text-transform: capitalize;
	margin-top: 30px;
}
.customer-service span.link a {
	display: flex;
	align-items: center;
	color: var(--primary-color);
}
.customer-service .time dl.opening-hours__times {
	margin-top: 8px !important;
}
.customer-service .time .opening-hours__times dt,
.customer-service .time .opening-hours__times dd {
	white-space: nowrap;
	display: inline !important;
	margin-right: 5px;
}

.customer-service .time .description-list dd {
	display: inline-flex;
	flex: none;
}
.customer-service .number-and-time {
	margin-bottom: 15px;
}
.customer-service .number-and-time > * {
	flex: 1;
}

.customer-service .phonenumber {
	display: flex;
	align-items: center;
	margin-top: 6px;
}

.customer-service .time {
	display: inline-flex;
	align-items: center;
}

.customer-service .time .description-list {
	display: inline-block;
	align-items: center;
}

.customer-service .time .description-list > * {
	max-width: 100%;
	min-width: 0;
	padding: 0;
	display: flex;
	justify-content: center;
	flex: 1;
}

.customer-service .email a {
	word-break: break-all;
}
.customer-service .email div {
	overflow: unset;
}

@media (max-width: 950px) {
	.customer-service .time .opening-hours__times dt,
	.customer-service .time .opening-hours__times dd {
		display: inline-flex !important;
	}
}
