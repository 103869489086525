.agreement-title {
	display: flex;
	align-items: center;
}

.agreement-title i {
	margin: 0 var(--spacing-half) 0 0;
}

.agreement-title span:after {
	content: '|';
	margin: 0 10px;
	display: inline;
}
.agreement-title span:last-child:after {
	content: '';
	margin: 0;
}
.agreement-title i {
	color: rgba(0, 0, 0, 0.54);
	float: left;
	font-size: 22px;
	margin-right: 10px;
}

@media only screen and (max-width: 767px) {
	.agreement-title span:after {
		content: '';
		margin: 0;
	}
	.agreement-title-name,
	.agreement-title-period {
		display: none;
	}
}
