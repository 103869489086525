.app {
	margin-top: var(--header-height);
	overflow-x: hidden;
}
.impersonator-banner {
	position: fixed;
	top: 0;
	width: 100%;
	text-align: center;
	z-index: 2;
	background-color: #444444;
	color: #fff;
	height: var(--impersonator-banner-height);
	font-weight: bold;
}
.app__main-content {
	display: block;
	position: relative;
	min-height: calc(100vh - var(--header-height));
	height: 100%;
	padding: 0 17px;
	padding-top: 60px;
}
.app__main-content:before {
	content: '';
	background-color: white;
	position: absolute;
	width: 100%;
	height: 100%;
	display: block;
	top: 0;
	left: 0;
	opacity: var(--background-fade);
}
.app__main-content > * {
	position: relative;
	max-width: var(--max-width);
	margin-left: auto;
	margin-right: auto;
}

.app__main-content .view-sustainable,
.app__main-content .dashboard {
	max-width: var(--max-dashboard-width);
}

.app_relation_not_synced {
	min-height: calc(100vh - var(--header-height));
	max-width: var(--max-width);
	margin-left: auto;
	margin-right: auto;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
}

.spinner-component {
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
}

.spinner-component > * > span {
	background-color: var(--primary-color);
}

.pagination-wrapper {
	width: 100%;
	display: flex;
	justify-content: center;
	align-content: center;
	margin: var(--spacing-default) 0 0 0;
}

.pagination {
	display: inline-flex;
	justify-content: center;
	align-items: center;
	align-content: center;
	list-style: none;
	padding: 0;
	margin: 0;
}

.pagination li {
	flex: 1 1 auto;
	display: flex;
	justify-content: center;
	align-items: center;
	align-content: center;
	list-style: none;
	margin: 0 var(--spacing-half) 0 0;
}

.pagination li:last-child {
	margin: 0;
}

.pagination li a {
	display: flex;
	justify-content: center;
	align-items: center;
	align-content: center;
	color: rgba(0, 0, 0, 1);
	border: 1px solid var(--collapsible-header-border-color);
	text-decoration: none;
	padding: 0 var(--spacing-half);
}

.pagination li:first-child a,
.pagination li:nth-child(2) a,
.pagination li:last-child a,
.pagination li:nth-last-child(2) a {
	border: 0;
	color: rgba(0, 0, 0, 0.54);
	padding: 0;
}

.pagination li.disabled a {
	color: rgba(0, 0, 0, 0.3);
}

.pagination li.active a {
	color: var(--primary-color);
	background-color: rgba(var(--primary-colorRGB), 0.05);
	border: 1px solid rgba(var(--primary-colorRGB), 0.05);
}

.tooltip-wrapper .__react_component_tooltip * {
	text-align: center;
	font-size: 1rem;
}

.tooltip-wrapper .tooltip-link {
	color: var(--primary-color);
	cursor: pointer;
}

.tooltip-wrapper .tooltip-link:hover {
	text-decoration: underline;
}

.tooltip-wrapper .extra-tooltip:hover {
	visibility: visible !important;
	opacity: 1 !important;
}

.__react_component_tooltip {
	pointer-events: auto !important;
}

.tooltip-wrapper:hover + .extra-tooltip {
	visibility: visible !important;
	opacity: 1 !important;
}

.tooltip-wrapper + .extra-tooltip:hover {
	visibility: visible !important;
	opacity: 1 !important;
}

.tooltip-wrapper .extra-tooltip {
	max-width: 500px;
	background-color: var(--tooltip-background-color);
	border-radius: var(--border-radius);
	border: 1px solid var(--tooltip-border-color);
	box-shadow: 0 2px 2px 0px rgba(0, 0, 0, 0.25);
	opacity: 1;
	z-index: 1000;
	padding: var(--spacing-half) var(--spacing-default);
}

.tooltip-wrapper .extra-tooltip.place-bottom:before {
	width: 10px;
	height: 10px;
	border: 0;
	transform: rotate(45deg);
	border-top: 1px solid var(--tooltip-border-color);
	border-left: 1px solid var(--tooltip-border-color);
	background-color: var(--tooltip-background-color);
	margin-left: -5px;
	top: -6px;
}

.tooltip-wrapper .extra-tooltip.place-bottom:after {
	content: none;
}

.tooltip-wrapper .extra-tooltip * {
	color: black;
}

.tooltip-wrapper .extra-tooltip a {
	color: var(--primary-color);
	cursor: pointer;
}

.tooltip-wrapper .extra-tooltip a:hover {
	text-decoration: underline;
}

.edit-row > * {
	display: inline-flex;
}

.text-overflow {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	display: block;
}
.flex-row {
	display: flex;
	width: 100%;
	align-items: center;
	align-content: center;
}
.flex-inline {
	display: inline-flex;
	align-items: center;
	align-content: center;
}
.react-switch .react-switch-bg {
	box-shadow: inset 0px 0px 5px rgba(0, 0, 0, 0.4);
}

.react-switch .react-switch-handle {
	box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.4);
}

.icon svg {
	fill: var(--default-icon-color);
}
.react-datepicker-popper {
	z-index: 2;
}

@media only screen and (max-width: 767px) {
	.tooltip-wrapper .extra-tooltip {
		max-width: 100%;
	}
	.app__main-content {
		padding: 0 10px;
	}
}

.global-notification {
	display: flex;
    background-color: wheat;
    justify-content: center;
    align-items: center;
    position: fixed;
	top: var(--header-height);
    z-index: 1;
    width: 100%;
	padding: 12px;
	min-height: 66px;
}
.global-notification-login-page {
	display: flex;
    background-color: wheat;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: var(--header-height);
    z-index: 1;
    width: 100%;
    padding: 12px;
    min-height: 66px;
    right: 0;
}