.latest-invoices-content {
	padding: 20px 0;
	margin: 0 20px;
	opacity: 1;
	background-color: #fff;
	border-top: 1px solid var(--collapsible-header-border-color);
	position: relative;
}

.latest-invoices {
	display: grid;
	grid-template-columns: 22px 35% 20% 25%;
	align-items: center;
	grid-column-gap: 10px;
}
.latest-invoices p {
	margin: 5px 0;
}
.latest-invoices p:nth-child(2) {
	justify-self: start;
	max-width: 100%;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}
.latest-invoices p:nth-child(3) {
	justify-self: end;
}
.latest-invoices p:nth-child(4) {
	justify-self: start;
	max-width: 100%;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}
.all-invoices-button {
	display: flex;
	align-items: center;
	margin-left: auto;
	margin-top: 20px;
	text-transform: capitalize;
	color: var(--primary-color);
}
.latest-invoices-desktop {
	position: relative;
}

@media (min-width: 768px) {
	.all-invoices-button {
		position: absolute;
		right: 20px;
		bottom: 20px;
	}
}
@media (min-width: 768px) and (max-width: 1024px) {
	.latest-invoices {
		margin-left: -15px;
		grid-template-columns: 22px 32% 27% 32%;
		grid-column-gap: 5px;
	}
}

@media (max-width: 767px) {
	.latest-invoices {
		grid-template-columns: 22px 30% 53% 11%;
	}
}
@media (max-width: 600px) {
	.latest-invoices {
		grid-template-columns: 22px 30% 45% 15%;
	}
}
@media (max-width: 468px) {
	.latest-invoices {
		grid-template-columns: 22px 30% 25% 25%;
	}
}
