.select {
	display: flex;
	justify-content: space-between;
	position: relative;
	border: 1px solid var(--input-border-color);
}

.select--disabled:after {
	opacity: 0;
}
.select--disabled .icon {
	opacity: 0.5;
}
.select select {
	min-height: 25px;
	background-color: transparent;
	border: none;
	appearance: none;
	font-size: 1rem;
	flex: 1;
	line-height: 1.75em;
	padding: 0;
	display: inline-flex;
	align-items: center;
	position: relative;
	z-index: 1;
	font-family: inherit;
}
.select .icon {
	position: absolute;
	right: -5px;
	z-index: 1;
}
.select--invalid:after {
	background-color: var(--error-color);
	opacity: 1;
}

.ios .select select {
	font-size: 16px;
}

select::-ms-expand {
	display: none;
}
