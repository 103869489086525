.notifications {
	padding-top: var(--spacing-half);
}
.notifications ul {
	list-style-type: none;
	list-style-image: url('warning16.gif');
}
.notifications .panel.invalid {
	border: 1px solid red;
}

.notifications .panel.invalid li {
	color: red;
}
