.table {
	width: 100%;
	border-collapse: collapse;
}

.table td {
	line-height: 1em;
}

.table thead tr th {
	font-weight: bold;
}
